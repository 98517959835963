import React, { useState } from 'react';
import styles from './DashboardContent.module.css';
import { FaRobot, FaCircle } from 'react-icons/fa';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Slider from '@mui/material/Slider';

const salesData = Array.from({ length: 21 }, (_, i) => ({
    name: `Day ${i + 1}`,
    sales: Math.floor(Math.random() * 500) + 100,
}));

const userData = Array.from({ length: 21 }, (_, i) => ({
    name: `Day ${i + 1}`,
    users: Math.floor(Math.random() * 3000) + 1000,
}));

const activeUsersData = [
    { name: 'Online', value: 130 },
    { name: 'Offline', value: 50 },
    { name: 'Do Not Disturb', value: 27 },
    { name: 'Idle', value: 10 },
];

const DashboardContent = () => {
    const [salesTimeRange, setSalesTimeRange] = useState([0, 20]);
    const [usersTimeRange, setUsersTimeRange] = useState([0, 20]);

    const handleSalesTimeRangeChange = (event, newValue) => {
        setSalesTimeRange(newValue);
    };

    const handleUsersTimeRangeChange = (event, newValue) => {
        setUsersTimeRange(newValue);
    };

    const filteredSalesData = salesData.slice(salesTimeRange[0], salesTimeRange[1] + 1);
    const filteredUserData = userData.slice(usersTimeRange[0], usersTimeRange[1] + 1);

    return (
        <div className={styles.adminDashboardContent}>
            <div className={styles.infoBoxes}>
                <div className={styles.infoBox}>
                    <FaRobot className={styles.infoIcon} />
                    <div className={styles.infoText}>
                        <h2>Bots <span>169</span></h2>
                    </div>
                </div>
                <div className={styles.infoBox}>
                    <FaCircle className={styles.infoIconGreen} />
                    <div className={styles.infoText}>
                        <h2>Online <span>130</span></h2>
                    </div>
                </div>
                <div className={styles.infoBox}>
                    <FaCircle className={styles.infoIconRed} />
                    <div className={styles.infoText}>
                        <h2>Do Not Disturb <span>27</span></h2>
                    </div>
                </div>
                <div className={styles.infoBox}>
                    <FaCircle className={styles.infoIconOrange} />
                    <div className={styles.infoText}>
                        <h2>Idle <span>10</span></h2>
                    </div>
                </div>
                <div className={styles.infoBox}>
                    <FaCircle className={styles.infoIconBlack} />
                    <div className={styles.infoText}>
                        <h2>Invisible <span>1</span></h2>
                    </div>
                </div>
            </div>
            <div className={styles.infoBoxesLarge}>
                <div className={styles.infoBoxLarge}>
                    <FaRobot className={styles.infoIcon} />
                    <div className={styles.infoText}>
                        <h2>New Bots <span>50</span></h2>
                    </div>
                </div>
                <div className={styles.infoBoxLarge}>
                    <FaCircle className={styles.infoIconGreen} />
                    <div className={styles.infoText}>
                        <h2>Active Users <span>200</span></h2>
                    </div>
                </div>
                <div className={styles.infoBoxLarge}>
                    <FaCircle className={styles.infoIconRed} />
                    <div className={styles.infoText}>
                        <h2>Errors <span>5</span></h2>
                    </div>
                </div>
            </div>
            <div className={styles.infoBoxesLarge}>
                <div className={styles.infoBoxLarge}>
                    <h2>Sales</h2>
                    <LineChart width={600} height={300} data={filteredSalesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="sales" stroke="#565FFF" activeDot={{ r: 8 }} />
                    </LineChart>
                    <div className={styles.sliderContainer}>
                        <Slider
                            value={salesTimeRange}
                            onChange={handleSalesTimeRangeChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={20}
                        />
                    </div>
                </div>
                <div className={styles.infoBoxLarge}>
                    <h2>Users</h2>
                    <BarChart width={600} height={300} data={filteredUserData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="users" fill="#FFFFFF" />
                    </BarChart>
                    <div className={styles.sliderContainer}>
                        <Slider
                            value={usersTimeRange}
                            onChange={handleUsersTimeRangeChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={20}
                        />
                    </div>
                    <div className={styles.activeUsers}>
                        <div className={styles.infoBox}>
                            <FaCircle className={styles.infoIconGreen} />
                            <div className={styles.infoText}>
                                <h2>Online <span>130</span></h2>
                            </div>
                        </div>
                        <div className={styles.infoBox}>
                            <FaCircle className={styles.infoIconRed} />
                            <div className={styles.infoText}>
                                <h2>Offline <span>50</span></h2>
                            </div>
                        </div>
                        <div className={styles.infoBox}>
                            <FaCircle className={styles.infoIconOrange} />
                            <div className={styles.infoText}>
                                <h2>Do Not Disturb <span>27</span></h2>
                            </div>
                        </div>
                        <div className={styles.infoBox}>
                            <FaCircle className={styles.infoIconBlack} />
                            <div className={styles.infoText}>
                                <h2>Idle <span>10</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardContent;
