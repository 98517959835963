import React, { useState, useEffect } from 'react';
import styles from './ActionLog.module.css';

const ActionLog = () => {
    const [logEntrys, setLogEntrys] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [usernameFilter, setUsernameFilter] = useState('');
    const [userIdFilter, setUserIdFilter] = useState('');
    const [actionFilter, setActionFilter] = useState('');

    useEffect(() => {
        const log = JSON.parse(localStorage.getItem('botDetails'))[0].logs;
        const logArray = log.split('|bigcut§!').map(entry => {
            const [userInfo, action] = entry.split(' hat folgende Aktion am BOT durchgeführt: ');
            const [username, userId] = userInfo.split(' (');
            return {
                username: username.trim(),
                userId: userId ? userId.replace(')', '').trim() : '<none>',
                action: action ? action.trim() : '<none>'
            };
        });
        setLogEntrys(logArray);
    }, []);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const actionMapping = {
        'Name geändert': 'Name geändert',
        'Avatar geändert': 'Avatar geändert',
        'Banner geändert': 'Banner geändert',
        'Module gespeichert': 'Module gespeichert',
        'Module wurde gespeichert': 'Module gespeichert',
        'wurde gespeichert': 'Module gespeichert',
        'Server geändert': 'Server geändert',
        'Admin hinzugefügt': 'Admin Change',
        'Admin entfernt': 'Admin Change',
        'Owner hinzugefügt': 'Owner Change',
        'Owner entfernt': 'Owner Change'
    };

    const getGroupedAction = (action) => {
        for (const key in actionMapping) {
            if (action.includes(key)) {
                return actionMapping[key];
            }
        }
        return action;
    };

    const uniqueUsernames = [...new Set(logEntrys.map(entry => entry.username))];
    const uniqueUserIds = [...new Set(logEntrys.map(entry => entry.userId))];
    const uniqueActions = [...new Set(logEntrys.map(entry => getGroupedAction(entry.action)))];

    const filteredLogEntrys = logEntrys.filter(entry => 
        (usernameFilter === '' || entry.username === usernameFilter) &&
        (userIdFilter === '' || entry.userId === userIdFilter) &&
        (actionFilter === '' || getGroupedAction(entry.action) === actionFilter)
    );

    return (
        <div className={styles.actionLog}>
            <h2>Action Log</h2>
            <div className={styles.filters}>
                <select value={usernameFilter} onChange={e => setUsernameFilter(e.target.value)}>
                    <option value="">All Usernames</option>
                    {uniqueUsernames.map((username, index) => (
                        <option key={index} value={username}>{username}</option>
                    ))}
                </select>
                <select value={userIdFilter} onChange={e => setUserIdFilter(e.target.value)}>
                    <option value="">All User IDs</option>
                    {uniqueUserIds.map((userId, index) => (
                        <option key={index} value={userId}>{userId}</option>
                    ))}
                </select>
                <select value={actionFilter} onChange={e => setActionFilter(e.target.value)}>
                    <option value="">All Actions</option>
                    {uniqueActions.map((action, index) => (
                        <option key={index} value={action}>{action}</option>
                    ))}
                </select>
            </div>
            <ul className={styles.logList} onClick={togglePopup}>
                {filteredLogEntrys.map((entry, index) => (
                    <li key={index}>
                        <strong>{entry.username}</strong> ({entry.userId}) hat folgende Aktion am BOT durchgeführt: {entry.action}
                    </li>
                ))}
            </ul>

            {showPopup && (
                <div className={styles.popupOverlay} onClick={togglePopup}>
                    <div className={styles.popupContent} onClick={e => e.stopPropagation()}>
                        <h2>Alle Log Einträge</h2>
                        <div className={styles.filters}>
                            <select value={usernameFilter} onChange={e => setUsernameFilter(e.target.value)}>
                                <option value="">All Usernames</option>
                                {uniqueUsernames.map((username, index) => (
                                    <option key={index} value={username}>{username}</option>
                                ))}
                            </select>
                            <select value={userIdFilter} onChange={e => setUserIdFilter(e.target.value)}>
                                <option value="">All User IDs</option>
                                {uniqueUserIds.map((userId, index) => (
                                    <option key={index} value={userId}>{userId}</option>
                                ))}
                            </select>
                            <select value={actionFilter} onChange={e => setActionFilter(e.target.value)}>
                                <option value="">All Actions</option>
                                {uniqueActions.map((action, index) => (
                                    <option key={index} value={action}>{action}</option>
                                ))}
                            </select>
                        </div>
                        <ul>
                            {filteredLogEntrys.map((entry, index) => (
                                <li key={index}>
                                    <strong>{entry.username}</strong> ({entry.userId}) hat folgende Aktion am BOT durchgeführt: {entry.action}
                                </li>
                            ))}
                        </ul>
                        <button className={styles.closeButton} onClick={togglePopup}>X</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActionLog;