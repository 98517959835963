import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import CreateBotPage from './pages/CreateBotPage';
import BotPage from './pages/BotPage';
import ConfigPage from './pages/ConfigPage';
import TosPage from './pages/TosPage';
import ImpressumPage from './pages/ImpressumPage';
import ErrorPage from './pages/ErrorPage';
import CommandPage from './pages/CommandPage';
import RegisterPage from './pages/RegisterPage';
import RegisterdPage from './pages/RegisterdPage';
import AdminDashboardPage from './pages/admin/AdminDashboard';
import { MessageProvider } from './context/MessageContext'; // Import MessageProvider
import MessageComponent from './components/MessageComponent'; // Import MessageComponent

function App() {
  return (
    <MessageProvider>
      <Router>
        <MessageComponent /> {/* Include MessageComponent */}
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/dashboard/bot" element={<BotPage />} />
          <Route path="dashboard/bot/config/*" element={<ConfigPage />} />
          <Route path="/create-bot" element={<CreateBotPage />} />
          <Route path="/terms-of-service" element={<TosPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/commands" element={<CommandPage />} />
          <Route path="/register/*" element={<RegisterPage />} />
          <Route path="/registered" element={<RegisterdPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/admin" element={<AdminDashboardPage />} />
        </Routes>
      </Router>
    </MessageProvider>
  );
}

export default App;