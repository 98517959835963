import React from 'react';
import Select from 'react-select';

// const forumString = JSON.parse(localStorage.getItem('botDetails'))[0].forums;

const parseRoles = () => {
    if (!localStorage.getItem('botDetails')) return [];
    if (JSON.parse(localStorage.getItem('botDetails'))[0].roles === '<none>') return [];

    const roleString = JSON.parse(localStorage.getItem('botDetails'))[0].roles;
    return roleString.split('|bigcut§!').map(role => {
        const [label, value, color] = role.split('|smallcut§!');
        return {
            label: label.trim(),
            value: value.trim(),
            color: color ? color.trim().replace('rgb', 'rgb(') + ')' : undefined
        };
    });
};

const parseCategories = () => {
    if (!localStorage.getItem('botDetails')) return [];
    if (JSON.parse(localStorage.getItem('botDetails'))[0].categorys === '<none>') return [];

    const categoryString = JSON.parse(localStorage.getItem('botDetails'))[0].categorys;
    return categoryString.split(',').map(category => {
        const [label, id] = category.match(/Category:(.*)\(id=(\d+)\)/).slice(1, 3);
        return {
            label: label.trim(),
            value: id.trim()
        };
    });
};

const parseChannels = () => {
    if (!localStorage.getItem('botDetails')) return [];
    if (JSON.parse(localStorage.getItem('botDetails'))[0].channels === '<none>') return [];

    const channelString = JSON.parse(localStorage.getItem('botDetails'))[0].channels;
    return channelString.split('|bigcut§!').map(channel => {
        const [label, id] = channel.split('|smallcut§!');
        return {
            label: label.trim(),
            value: id.trim()
        };
    });
};



const parseVoiceChannels = () => {
    if (!localStorage.getItem('botDetails')) return [];
    if (JSON.parse(localStorage.getItem('botDetails'))[0].voicechannels === '<none>') return [];

    const voiceChannelString = JSON.parse(localStorage.getItem('botDetails'))[0].voicechannels;
    return voiceChannelString.split('|bigcut§!').map(voiceChannel => {
        const [label, id] = voiceChannel.split('|smallcut§!');
        return {
            label: label.trim(),
            value: id.trim()
        };
    });
};

const dataOptions = {
    roles: parseRoles(),
    categories: parseCategories(),
    channels: parseChannels(),
    forums: [
        { label: "Announcements", value: "1" },
        { label: "Updates", value: "2" }
    ],
    voiceChannels: parseVoiceChannels(),
    buttonType: [
        { label: "Primary", value: "primary", color: "#007bff" },
        { label: "Secondary", value: "secondary", color: "#6c757d" },
        { label: "Success", value: "success", color: "#28a745" },
        { label: "Danger", value: "danger", color: "#dc3545" }
    ],
    codeType: [
        { label: "Random", value: "random" },
        { label: "Manual", value: "manual" }
    ]
};

const SelectInput = ({ selectedItems, onChange, type, multi }) => {
    const options = dataOptions[type];
    const selectedOptions = multi
        ? selectedItems.map(item => options.find(opt => opt.value === item.id))
        : options.find(opt => opt.value === selectedItems[0]?.id);
    return (
        <div style={{
            padding: '10px',
            backgroundColor: '#212234',
            border: '1px solid #565FFF',
            borderRadius: '10px',
            color: 'white',
            maxWidth: '100%',
            margin: '20px auto',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)'
        }}>
            <Select
                isMulti={multi}
                options={options}
                value={selectedOptions}
                onChange={onChange}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        backgroundColor: '#2c2f48',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '5px',
                        boxShadow: state.isFocused ? '0 0 5px #565FFF' : 'none',
                        outline: 'none',
                    }),
                    menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#3b3f5c',
                        color: 'white',
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#4a4f6e' : '#3b3f5c',
                        color: state.isFocused ? 'white' : '#ccc',
                        padding: '10px',
                    }),
                    multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: '#4343d3',
                        color: 'white',
                        borderRadius: '4px',
                    }),
                    multiValueLabel: (provided) => ({
                        ...provided,
                        color: 'white',
                    }),
                    multiValueRemove: (provided) => ({
                        ...provided,
                        color: 'white',
                        ':hover': {
                            backgroundColor: '#565FFF',
                            color: 'white',
                        },
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: 'white',
                    }),
                }}
                getOptionLabel={(option) => (
                    <div style={{ color: option.color || 'inherit' }}>
                        {option.label}
                    </div>
                )}
            />
        </div>
    );
};

export default SelectInput;
