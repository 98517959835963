import React from 'react';
import { FaTrash, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styles from './ConfigHeadbar.module.css';

const ConfigHeadbar = ({ title, onReset, backUrl }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.headbar}>
            <button className={styles.backButton} onClick={() => navigate(backUrl)}>
                <FaArrowLeft />
            </button>
            <h1 className={styles.title}>{title}</h1>
            <button className={styles.resetButton} onClick={onReset}>
                <FaTrash />
            </button>
        </div>
    );
};

export default ConfigHeadbar;
