import React from 'react';
import { FaExclamationTriangle, FaUndo } from 'react-icons/fa';
import styles from './Savebar.module.css';

const Savebar = ({ show, onSave, onSoftReset }) => {
    if (!show) return null;

    return (
        <div className={styles.savebar}>
            <FaExclamationTriangle className={styles.icon} />
            <span>Du hast ungespeicherte Änderungen. Willst du diese speichern?</span>
            <div className={styles.buttonContainer}>
                <button className={styles.softResetButton} onClick={onSoftReset}>
                    <FaUndo /> Soft Reset
                </button>
                <button className={styles.saveButton} onClick={onSave}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default Savebar;
