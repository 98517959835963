import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>© 2024 ayobots.de</p>
      <a href="https://discord.gg/ayobots" className={styles.footerLink} target="_blank" rel="noopener noreferrer">Discord</a>
      <div className={styles.footerLinks}>
        <a href="/impressum" className={styles.footerLink}>Impressum</a>| 
        <a href="/terms-of-service" className={styles.footerLink}>AGB / ToS</a>| 
        <a href="https://mc-host24.de/cmp/64056" className={styles.footerLink} target="_blank" rel="noopener noreferrer">Hoster</a>
      </div>
    </footer>
  );
};

export default Footer;