import React, { useState, useEffect, useRef } from 'react';
import styles from './UsersContent.module.css';
import userPlaceholder from '../../../assets/placeholder/userPlaceholder.png';

const UsersContent = () => {
    const [users, setUsers] = useState([]);
    const [ayocoreUsers, setAyoCoreUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermAyoCore, setSearchTermAyoCore] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [advancedInfo, setAdvancedInfo] = useState(null);
    const popupRef = useRef();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('https://ayodev.de:8443/admin/users', {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
                    }
                });
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        const fetchAyoCoreUsers = async () => {
            try {
                const response = await fetch('https://ayodev.de:8443/admin/ayocoreUsers', {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
                    }
                });
                const data = await response.json();
                setAyoCoreUsers(data);
            } catch (error) {
                console.error('Error fetching AyoCore users:', error);
            }
        };

        fetchUsers();
        fetchAyoCoreUsers();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setSelectedUser(null);
                setAdvancedInfo(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchAyoCore = (event) => {
        setSearchTermAyoCore(event.target.value);
    };

    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.global_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.discord_id.toString().includes(searchTerm)
    );

    const filteredAyoCoreUsers = ayocoreUsers.filter(user =>
        user.username.toLowerCase().includes(searchTermAyoCore.toLowerCase()) ||
        user.global_name.toLowerCase().includes(searchTermAyoCore.toLowerCase()) ||
        user.discordId.toString().includes(searchTermAyoCore)
    );

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setAdvancedInfo(null);
    };

    const handleClosePopup = () => {
        setSelectedUser(null);
        setAdvancedInfo(null);
    };

    const handleShowMoreInfo = async () => {
        try {
            const response = await fetch('https://ayodev.de:8443/admin/getAdvancedUserInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
                },
                body: JSON.stringify({ email: selectedUser.email })
            });
            const data = await response.json();
            setAdvancedInfo(data);
        } catch (error) {
            console.error('Error fetching advanced user info:', error);
        }
    };

    const handleImageError = (event) => {
        event.target.src = userPlaceholder;
    };

    const renderUserDetails = (user) => {
        const isAyoCoreUser = user.hasOwnProperty('discordId');
        return (
            <>
                <img src={`${user.avatar}`} alt="Avatar" className={styles.avatar} onError={handleImageError} />
                <p><strong>Discord ID:</strong> {isAyoCoreUser ? user.discordId : user.discord_id}</p>
                <p><strong>Username:</strong> {user.username}</p>
                <p><strong>Global Name:</strong> {user.global_name}</p>
                <p><strong>Email:</strong> {user.email}</p>
                {!isAyoCoreUser && <p><strong>Verified:</strong> {user.verified ? 'Yes' : 'No'}</p>}
                <p><strong>Created At:</strong> {isAyoCoreUser ? user.registered_at : user.created_at}</p>
                <p><strong>IP Address:</strong> {isAyoCoreUser ? user.ip : user.ip_address}</p>
            </>
        );
    };

    return (
        <div className={styles.usersContent}>
            <h2 className={styles.tableHeader}>Dashboard Login Table</h2>
            <div className={styles.tableContainer}>
                <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                />
                <table className={styles.usersTable}>
                    <thead>
                        <tr>
                            <th>Discord ID</th>
                            <th>Username</th>
                            <th>Global Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map(user => (
                            <tr key={user.id} onClick={() => handleRowClick(user)}>
                                <td>{user.discord_id}</td>
                                <td>{user.username}</td>
                                <td>{user.global_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <h2 className={styles.tableHeader}>AyoCore Registered Table</h2>
            <div className={styles.tableContainer}>
                <input
                    type="text"
                    placeholder="Search AyoCore users..."
                    value={searchTermAyoCore}
                    onChange={handleSearchAyoCore}
                    className={styles.searchInput}
                />
                <table className={styles.usersTable}>
                    <thead>
                        <tr>
                            <th>Discord ID</th>
                            <th>Username</th>
                            <th>Global Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAyoCoreUsers.map(user => (
                            <tr key={user.id} onClick={() => handleRowClick(user)}>
                                <td>{user.discordId}</td>
                                <td>{user.username}</td>
                                <td>{user.global_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedUser && (
                <div className={styles.userPopup}>
                    <div className={styles.popupContent} ref={popupRef}>
                        <button className={styles.closeButton} onClick={handleClosePopup}>Close</button>
                        <h2>User Details</h2>
                        {renderUserDetails(selectedUser)}
                        <button className={styles.moreInfoButton} onClick={handleShowMoreInfo}>Show More Info</button>
                        {advancedInfo && (
                            <div className={styles.advancedInfo}>
                                <h3>Advanced Information</h3>
                                {advancedInfo.Count === "0" ? (
                                    <p>No additional information found.</p>
                                ) : (
                                    <>
                                        <p><strong>Count:</strong> {advancedInfo.Count}</p>
                                        <p><strong>Computer Names:</strong> {advancedInfo["Computer Names"].join(', ')}</p>
                                        <p><strong>Operating Systems:</strong> {advancedInfo["Operating Systems"].join(', ')}</p>
                                        <p><strong>Date Compromised:</strong> {advancedInfo["Date Compromised"].join(', ')}</p>
                                        <p><strong>Malware Paths:</strong> {advancedInfo["Malware Paths"].join(', ')}</p>
                                        <p><strong>IPs:</strong> {advancedInfo.IPs.join(', ')}</p>
                                        <p><strong>Username:Password Combos:</strong> {advancedInfo["Username:Password Combos"].join(', ')}</p>
                                        <p><strong>Antiviruses:</strong> {advancedInfo.Antiviruses.map(av => av.join(', ')).join(' | ')}</p>
                                        <p><strong>Passwords:</strong> {advancedInfo.Passwords.map(pw => pw.join(', ')).join(' | ')}</p>
                                        <p><strong>Logins:</strong> {advancedInfo.Logins.map(login => login.join(', ')).join(' | ')}</p>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UsersContent;
