import React from 'react';
import styles from './StatsCarousel.module.css';

function StatsCarousel() {
    const statsData = [
        { title: "Active Bots", description: "Current number of active bots: 100" },
        { title: "All-Time Members", description: "Total members ever joined: 10,000" },
        { title: "Total Commands", description: "Commands processed: 50,000" },
        { title: "Messages Sent", description: "Total messages sent by bots: 200,000" },
        { title: "Channels Monitored", description: "Number of channels monitored: 150" },
        { title: "Active Servers", description: "Servers currently using the bot: 500" },
        { title: "Commands Available", description: "Total available commands: 300" },
        { title: "Uptime", description: "Bot uptime in days: 99" },
        { title: "Errors Logged", description: "Total errors logged: 1,000" },
        { title: "Users Banned", description: "Total users banned: 50" },
        { title: "Roles Managed", description: "Total roles managed: 1,200" },
        { title: "Messages Deleted", description: "Total messages deleted: 5,000" },
        { title: "Voice Channels Joined", description: "Total voice channels joined: 2,000" },
        { title: "Reactions Added", description: "Total reactions added: 10,000" },
        { title: "DMs Sent", description: "Total direct messages sent: 3,000" },
        { title: "Mentions", description: "Total mentions: 25,000" },
        { title: "Files Uploaded", description: "Total files uploaded: 7,500" },
        { title: "Polls Created", description: "Total polls created: 200" },
        { title: "Games Played", description: "Total games played: 1,500" },
        { title: "Reminders Set", description: "Total reminders set: 800" },
        { title: "Events Scheduled", description: "Total events scheduled: 100" },
        { title: "Custom Commands", description: "Total custom commands: 50" },
        { title: "Bot Restarts", description: "Total bot restarts: 20" },
        { title: "New Members Today", description: "New members joined today: 50" },
        { title: "Voice Minutes", description: "Total voice minutes: 10,000" },
        { title: "Images Shared", description: "Total images shared: 5,000" },
        { title: "Links Shared", description: "Total links shared: 2,500" },
        { title: "Emojis Used", description: "Total emojis used: 15,000" },
        { title: "Nicknames Changed", description: "Total nicknames changed: 1,000" },
        { title: "Warnings Issued", description: "Total warnings issued: 300" },
        { title: "Kicks Issued", description: "Total kicks issued: 100" },
        { title: "Invites Created", description: "Total invites created: 400" },
        { title: "Invites Used", description: "Total invites used: 350" },
        { title: "Surveys Conducted", description: "Total surveys conducted: 50" },
        { title: "Announcements Made", description: "Total announcements made: 200" },
        { title: "Bot Updates", description: "Total bot updates: 30" },
        { title: "Feedback Received", description: "Total feedback received: 500" },
        { title: "Support Tickets", description: "Total support tickets: 150" },
        { title: "Quizzes Taken", description: "Total quizzes taken: 75" },
        { title: "Trivia Questions", description: "Total trivia questions: 1,000" },
        { title: "Music Played", description: "Total music tracks played: 5,000" }
    ];

// Split statsData into 4 levels for different rows
const rows = [];
const itemsPerRow = Math.ceil(statsData.length / 4);

for (let i = 0; i < 4; i++) {
    rows.push(statsData.slice(i * itemsPerRow, (i + 1) * itemsPerRow));
}

return (
    <div className={styles.carouselContainer}>
        {rows.map((row, index) => (
            <div key={index} className={`${styles.carouselTrack} ${styles[`track${index + 1}`]}`}>
                {row.map((stat, idx) => (
                    <div key={idx} className={styles.carouselItem}>
                        <h4>{stat.title}</h4>
                        <p>{stat.description}</p>
                    </div>
                ))}
            </div>
        ))}
    </div>
);
}

export default StatsCarousel;
