import React from 'react';
import styles from './HeroSection.module.css';

function HeroSection() {
    return (
        <section className={styles.heroSection}>
            <div className={styles.heroContent}>
                <h2 className={styles.heroTitle}>Willkommen bei Ayo Bots</h2>
                <p className={styles.heroText}>Verwalte, erkunde und passe deine Bots ganz einfach an.</p>
                <p className={styles.heroText}>Trete unserer Community bei und erhalte Unterstützung und Updates zu deinen Bots.</p>
                <a href="https://discord.gg/ayobots" className={styles.discordLink} target="_blank" rel="noopener noreferrer">Jetzt unserem Discord beitreten</a>
            </div>
        </section>
    );
}

export default HeroSection;