import React, { createContext, useState } from 'react';

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);

    const sendMessage = (text, type) => {
        const newMessage = { text, type, timeout: null };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        const index = messages.length;
        newMessage.timeout = setTimeout(() => removeMessage(index, 500), 5000); // Adjust timing for fade-out
    };

    const removeMessage = (index, delay) => {
        setTimeout(() => {
            setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
        }, delay);
    };

    return (
        <MessageContext.Provider value={{ messages, sendMessage, removeMessage }}>
            {children}
        </MessageContext.Provider>
    );
};
