import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CommandPage.module.css';

const CommandPage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="command-container">
      <button className="back-button" onClick={handleBackClick}>↩</button>
      <h1 className="command-title">AyoBots Commands</h1>
      <p className="command-description">Übersicht aller !commands</p>
      <p className="command-info">Folgend siehst du eine Übersicht aller !commands von deinem BOT. Die Commands kannst du nur in deinem Server oder in den DMS von deinem BOT ausführen.</p>

      <h2 className="command-subheading">DM:</h2>
      <ul className="command-list">
        <li>!restart - Restarte deinen BOT</li>
        <li>!info - Info über deinen BOT</li>
        <li>!modules - Status über die Module</li>
        <li>!emojis - Liste der Emojis</li>
        <li>!resetemojis - Resete deine Emojis</li>
      </ul>

      <h2 className="command-subheading">Server:</h2>
      <ul className="command-list">
        <li>!pingroles_msg - Pingroles Embed schicken</li>
        <li>!teamliste_msg - Teamliste Embed schicken</li>
        <li>!top10eintrag_msg - Top10 Embed schicken</li>
        <li>!ticketsystem_msg - TicketSystem Embed schicken</li>
        <li>!verify_msg - Verify Embed schicken</li>
        <li>!supportnotify_msg - SupportNotify Embed schicken</li>
        <li>!abmeldung_msg - Abmeldung Embed schicken</li>
      </ul>
    </div>
  );
};

export default CommandPage;