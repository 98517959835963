import React, { useEffect, useState } from 'react';
import styles from './RegisterPage.module.css';

function RegisterPage() {
    const [oauthLink, setOauthLink] = useState('');
    const [botId, setBotId] = useState('');
    const [botAvatar, setBotAvatar] = useState('https://placehold.co/400x400/png');
    const [botName, setBotName] = useState('Register Page');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const alias = window.location.pathname.split('/').pop();
        
        if (!alias) {
            localStorage.clear();
            window.location.href = '/';
        }

        localStorage.setItem('alias', alias);

        if (alias) {
            fetch(`https://ayodev.de:8443/ayocore/getOauthLink?alias=${alias}`)
                .then(response => response.json())
                .then(data => {
                    if (data.link) {
                        setOauthLink(data.link);
                        setBotId(data.discordId);
                        fetch(`https://ayodev.de:8443/ayocore/returnRegisterInfos?alias=${alias}`)
                            .then(response => response.json())
                            .then(info => {
                                setBotAvatar(info.Picture || 'https://placehold.co/400x400/png');
                                setBotName(info.Name || 'Register Page');
                                setDescription(info.Beschreibung || '');
                            })
                            .catch(error => console.error('Error fetching bot info:', error));
                    } else {
                        console.error('Alias not found');
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);
                });
        }
    }, []);

    const discordOauthLink = `${oauthLink}&state=${botId}`;

    if (loading) {
        return (
            <div className={styles.spinnerContainer}>
                <div className={styles.spinner}></div>
            </div>
        );
    }

    return (
        <div className={styles.registerPage}>
            <div className={styles.profile}>
                <img src={botAvatar} alt="Profile" />
                <div className={styles.profileName}>
                    <h1>{botName}</h1>
                </div>
                <p>{description}</p>
            </div>
            <a href={discordOauthLink}>
                <button>Connect with Discord</button>
            </a>
        </div>
    );
}

export default RegisterPage;
