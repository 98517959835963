async function fetchConfig() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const configName = pathSegments[pathSegments.length - 1];

    const response = await fetch('https://ayodev.de:8443/api/getConfig', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
        },
        body: JSON.stringify({ botToken: JSON.parse(localStorage.getItem('botToken')).botToken, configName: configName })
    });
    const data = await response.json();
    console.log(data);
    return JSON.parse(data.fileContent);
}

export { fetchConfig };