import React, { useState, useEffect, useRef } from 'react';
import styles from './AtButton.module.css';

const AtButton = ({ textBoxId }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [channels, setChannels] = useState([]);
    const [roles, setRoles] = useState([]);
    const [voiceChannels, setVoiceChannels] = useState([]);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const parseChannels = () => {
            if (!localStorage.getItem('botDetails')) return [];
            if (JSON.parse(localStorage.getItem('botDetails'))[0].channels === '<none>') return [];

            const channelString = JSON.parse(localStorage.getItem('botDetails'))[0].channels;
            return channelString.split('|bigcut§!').map(channel => {
                const [label, id] = channel.split('|smallcut§!');
                return {
                    id: id.trim(),
                    name: label.trim()
                };
            });
        };

        const parseRoles = () => {
            if (!localStorage.getItem('botDetails')) return [];
            if (JSON.parse(localStorage.getItem('botDetails'))[0].roles === '<none>') return [];

            const roleString = JSON.parse(localStorage.getItem('botDetails'))[0].roles;
            return roleString.split('|bigcut§!').map(role => {
                const [label, id, color] = role.split('|smallcut§!');
                return {
                    id: id.trim(),
                    name: label.trim(),
                    color: color ? color.trim().replace('rgb', 'rgb(') + ')' : undefined
                };
            });
        };

        const parseVoiceChannels = () => {
            if (!localStorage.getItem('botDetails')) return [];
            if (JSON.parse(localStorage.getItem('botDetails'))[0].voicechannels === '<none>') return [];

            const voiceChannelString = JSON.parse(localStorage.getItem('botDetails'))[0].voicechannels;
            return voiceChannelString.split('|bigcut§!').map(voiceChannel => {
                const [label, id] = voiceChannel.split('|smallcut§!');
                return {
                    id: id.trim(),
                    name: label.trim()
                };
            });
        };

        setChannels(parseChannels());
        setRoles(parseRoles());
        setVoiceChannels(parseVoiceChannels());
    }, []);

    const handleClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleOptionClick = (value) => {
        const textBox = document.getElementById(textBoxId);
        if (textBox) {
            textBox.value += value;
            textBox.dispatchEvent(new Event('input', { bubbles: true }));
        }
        setShowDropdown(false);
    };

    const handleVoiceChannelClick = (channelId) => {
        const guildId = JSON.parse(localStorage.getItem('currentBot')).guild_id;
        const url = `https://discord.com/channels/${guildId}/${channelId}`;
        handleOptionClick(url);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredChannels = channels.filter(channel => 
        channel.name.includes(searchTerm) || channel.id.includes(searchTerm)
    );
    const filteredRoles = roles.filter(role => 
        role.name.includes(searchTerm) || role.id.includes(searchTerm)
    );
    const filteredVoiceChannels = voiceChannels.filter(voiceChannel => 
        voiceChannel.name.includes(searchTerm) || voiceChannel.id.includes(searchTerm)
    );

    return (
        <div className={styles.container}>
            <button className={styles.button} onClick={handleClick}>@</button>
            {showDropdown && (
                <div className={styles.dropdown} ref={dropdownRef}>
                    <input
                        type="text"
                        className={styles.searchBar}
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className={styles.options}>
                        <div className={styles.optionGroup}>
                            <div className={styles.optionGroupTitle}>Channels</div>
                            {filteredChannels.map(channel => (
                                <div
                                    key={channel.id}
                                    className={styles.option}
                                    onClick={() => handleOptionClick(`<#${channel.id}>`)}
                                >
                                    #{channel.name}
                                </div>
                            ))}
                        </div>
                        <div className={styles.optionGroup}>
                            <div className={styles.optionGroupTitle}>Roles</div>
                            {filteredRoles.map(role => (
                                <div
                                    key={role.id}
                                    className={styles.option}
                                    onClick={() => handleOptionClick(`<@&${role.id}>`)}
                                    style={{ color: role.color }}
                                >
                                    @{role.name}
                                </div>
                            ))}
                        </div>
                        <div className={styles.optionGroup}>
                            <div className={styles.optionGroupTitle}>Voice Channels</div>
                            {filteredVoiceChannels.map(voiceChannel => (
                                <div
                                    key={voiceChannel.id}
                                    className={styles.option}
                                    onClick={() => handleVoiceChannelClick(voiceChannel.id)}
                                >
                                    🔊 {voiceChannel.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AtButton;
