import styles from './HeadBar.module.css';
import LogOutButton from './LogOutButton';
import logo from '../assets/images/logo.gif';
import { useEffect, useState } from 'react';

const HeadBar = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isMobile ? (
                <div className={styles.mobileHeadbar}>
                    <img className={styles.mobileHeadbarLogo} src={logo} alt="Logo" />
                    <h1 className={styles.mobileHeadline}>AYOBOTS</h1>
                    <LogOutButton className={styles.logoutButton} />
                </div>
            ) : (
                <div className={styles.dashboardHeadbar}>
                    <div className={styles.dashboardHeadbarText}>
                        <img className={styles.dashboardHeadbarLogo} src={logo} alt="Logo" />
                        <h1 className={styles.dashboardHeadline}><span className={styles.dashboardSpanHeadline}>AYOBOTS</span>Dashboard</h1>
                        <a href="/commands" className={styles.dashboardInfo}>Commands</a>
                        <p className={styles.dashboardInfo}>Info2</p>
                    </div>
                    <LogOutButton className={styles.logoutButton} />
                </div>
            )}
        </>
    );
}

export default HeadBar;