import React, { useContext } from 'react';
import styles from './MessageComponent.module.css';
import { MessageContext } from '../context/MessageContext';

const MessageComponent = () => {
    const { messages, removeMessage } = useContext(MessageContext);

    return (
        <div className={styles.messageContainer}>
            {messages.map((msg, index) => (
                <div
                    key={index}
                    className={`${styles.message} ${styles[`message${msg.type.charAt(0).toUpperCase() + msg.type.slice(1)}`]}`}
                    onMouseEnter={() => clearTimeout(msg.timeout)}
                    onMouseLeave={() => removeMessage(index, 5000)}
                    onClick={() => removeMessage(index, 0)}
                >
                    {msg.text}
                </div>
            ))}
        </div>
    );
};

export default MessageComponent;
