import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import styles from './MessageEditor.module.css';
import botAvatar from '/root/panel/src/assets/placeholder/botPlaceholder.png';
import AtButton from './buttons/AtButton';
import EmojiButton from './buttons/EmojiButton';
import FormattingButton from './buttons/FormattingButton';

const MessageEditor = ({ messageData, changeCallback, closeCallback }) => {
    const [message, setMessage] = useState(messageData.message.replace(/\\n/g, '\n'));
    const [charCount, setCharCount] = useState(messageData.message.length);
    const [errors, setErrors] = useState([]);
    const [embeds, setEmbeds] = useState(messageData.embeds);
    const [images, setImages] = useState(messageData.images);
    const [selfRoles, setSelfRoles] = useState(messageData.selfRoles);
    const [linkButtons, setLinkButtons] = useState(messageData.linkButtons);

    const handleMessageChange = (e) => {
        const newMessage = e.target.value;
        setMessage(newMessage);
        setCharCount(newMessage.length);
        if (newMessage.length > 2000) {
            setErrors([...errors, 'Message exceeds 2000 characters']);
        } else {
            setErrors(errors.filter(error => error !== 'Message exceeds 2000 characters'));
        }
    };

    const addEmbed = () => {
        if (embeds.length < 10) {
            setEmbeds([...embeds, { title: '', description: '', color: '#000000', fields: [], author: { name: '', url: '', iconUrl: '' }, footer: { text: '', iconUrl: '', timestamp: false }, image: '', thumbnail: '' }]);
        } else {
            setErrors([...errors, 'Maximum of 10 embeds allowed']);
        }
    };

    const toggleEmbedContent = (index) => {
        const embedContent = document.getElementById(`embedContent-${index}`);
        embedContent.classList.toggle(styles.active);
    };

    const handleEmbedChange = (index, field, value) => {
        const newEmbeds = [...embeds];
        const fields = field.split('.');
        if (fields.length > 1) {
            newEmbeds[index][fields[0]][fields[1]] = value;
        } else {
            newEmbeds[index][field] = value;
        }
        setEmbeds(newEmbeds);
    };

    const toggleFieldContent = (embedIndex, fieldIndex) => {
        const fieldContent = document.getElementById(`fieldContent-${embedIndex}-${fieldIndex}`);
        fieldContent.classList.toggle(styles.active);
    };

    const handleFieldChange = (embedIndex, fieldIndex, field, value) => {
        const newEmbeds = [...embeds];
        newEmbeds[embedIndex].fields[fieldIndex][field] = value;
        setEmbeds(newEmbeds);
    };

    const toggleDropdownContent = (index, type) => {
        const dropdownContent = document.getElementById(`${type}Content-${index}`);
        dropdownContent.classList.toggle(styles.active);
    };

    const addInlineField = (embedIndex) => {
        const newEmbeds = [...embeds];
        if (newEmbeds[embedIndex].fields.length < 25) {
            newEmbeds[embedIndex].fields.push({ name: '', value: '', inline: true });
            setEmbeds(newEmbeds);
        } else {
            setErrors([...errors, 'Maximum of 25 fields allowed']);
        }
    };

    const addImage = () => {
        if (images.length < 5) {
            setImages([...images, '']);
        } else {
            setErrors([...errors, 'Maximum of 5 images allowed']);
        }
    };

    const handleImageChange = (index, value) => {
        const newImages = [...images];
        newImages[index] = value;
        setImages(newImages);
    };

    const toggleImageContent = (index) => {
        const imageContent = document.getElementById(`imageContent-${index}`);
        if (imageContent) {
            imageContent.classList.toggle(styles.active);
        }
    };

    const addSelfRole = () => {
        if (selfRoles.length < 5) {
            setSelfRoles([...selfRoles, { type: "", style: "", label: "", emoji: "", role: { name: "", id: ""} }]);
        } else {
            setErrors([...errors, 'Maximum of 5 self-role buttons allowed']);
        }
    };

    const handleSelfRoleChange = (index, field, value) => {
        const newSelfRoles = [...selfRoles];
        const fields = field.split('.');
        if (fields.length > 1) {
            newSelfRoles[index][fields[0]][fields[1]] = value;
        } else {
            newSelfRoles[index][field] = value;
        }
        setSelfRoles(newSelfRoles);
    };

    const toggleSelfRoleContent = (index) => {
        const selfRoleContent = document.getElementById(`selfRoleContent-${index}`);
        if (selfRoleContent) {
            selfRoleContent.classList.toggle(styles.active);
        }
    };

    const addLinkButton = () => {
        if (linkButtons.length < 5) {
            setLinkButtons([...linkButtons, { label: "", url: "", emoji: "" }]);
        } else {
            setErrors([...errors, 'Maximum of 5 link buttons allowed']);
        }
    };

    const handleLinkButtonChange = (index, field, value) => {
        const newLinkButtons = [...linkButtons];
        newLinkButtons[index][field] = value;
        setLinkButtons(newLinkButtons);
    };

    const toggleLinkButtonContent = (index) => {
        const linkButtonContent = document.getElementById(`linkButtonContent-${index}`);
        if (linkButtonContent) {
            linkButtonContent.classList.toggle(styles.active);
        }
    };

    const saveAndClose = () => {
        const newData = { 
            message: message.replace(/\n/g, '\\n'), 
            embeds, 
            images, 
            selfRoles, 
            linkButtons 
        };
        changeCallback(newData);
        closeCallback();
    };

    const deleteEmbed = (index) => {
        const newEmbeds = embeds.filter((_, i) => i !== index);
        setEmbeds(newEmbeds);
    };

    const deleteImage = (index) => {
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
    };

    const deleteSelfRole = (index) => {
        const newSelfRoles = selfRoles.filter((_, i) => i !== index);
        setSelfRoles(newSelfRoles);
    };

    const deleteLinkButton = (index) => {
        const newLinkButtons = linkButtons.filter((_, i) => i !== index);
        setLinkButtons(newLinkButtons);
    };

    const getMentionName = (id, type) => {
        const botDetails = JSON.parse(localStorage.getItem('botDetails'))[0];
        if (type === 'role') {
            const roles = botDetails.roles.split('|bigcut§!').map(role => {
                const [label, roleId] = role.split('|smallcut§!');
                return { label: label.trim(), id: roleId.trim() };
            });
            const role = roles.find(role => role.id === id);
            return role ? `@${role.label}` : `@Role ${id}`;
        } else if (type === 'channel') {
            const channels = botDetails.channels.split('|bigcut§!').map(channel => {
                const [label, channelId] = channel.split('|smallcut§!');
                return { label: label.trim(), id: channelId.trim() };
            });
            const channel = channels.find(channel => channel.id === id);
            return channel ? `#${channel.label}` : `#Channel ${id}`;
        } else if (type === 'voiceChannel') {
            const voiceChannels = botDetails.voicechannels.split('|bigcut§!').map(voiceChannel => {
                const [label, channelId] = voiceChannel.split('|smallcut§!');
                return { label: label.trim(), id: channelId.trim() };
            });
            const voiceChannel = voiceChannels.find(voiceChannel => voiceChannel.id === id);
            return voiceChannel ? `🔊 ${voiceChannel.label}` : `🔊 Voice Channel ${id}`;
        }
        return '';
    };

    const handleChannelClick = (channelId) => {
        const guildId = JSON.parse(localStorage.getItem('currentBot')).guild_id;
        const url = `https://discord.com/channels/${guildId}/${channelId}`;
        window.open(url, '_blank');
    };

    const renderMessageWithEmojisAndMentions = (message) => {
        const emojiRegex = /<a?:(\w+):(\d+)>/g;
        const mentionRegex = /<(@&|#)(\d+)>/g;
        const voiceChannelRegex = /https:\/\/discord\.com\/channels\/\d+\/(\d+)/g;

        return message.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                {line.split(mentionRegex).map((part, index) => {
                    if (index % 3 === 0) {
                        return part.split(emojiRegex).map((subPart, subIndex) => {
                            if (subIndex % 3 === 0) {
                                return subPart.split(voiceChannelRegex).map((voicePart, voiceIndex) => {
                                    if (voiceIndex % 2 === 0) {
                                        return voicePart;
                                    } else {
                                        const voiceChannelId = voicePart;
                                        const voiceChannelName = getMentionName(voiceChannelId, 'voiceChannel');
                                        return <span key={voiceIndex} className={styles.voiceChannel} onClick={() => handleChannelClick(voiceChannelId)}>{voiceChannelName}</span>;
                                    }
                                });
                            } else if (subIndex % 3 === 1) {
                                const isAnimated = part.startsWith('<a:');
                                const emojiId = part.split(':')[2].replace('>', '').trim();
                                const emojiUrl = `https://cdn.discordapp.com/emojis/${emojiId}.${isAnimated ? 'gif' : 'png'}`;
                                return <img key={subIndex} src={emojiUrl} alt={subPart} className={styles.emojiImage} />;
                            }
                            return null;
                        });
                    } else if (index % 3 === 1) {
                        const mentionType = part === '@&' ? 'role' : 'channel';
                        const mentionId = line.split(mentionRegex)[index + 1];
                        const mentionName = getMentionName(mentionId, mentionType);
                        if (mentionType === 'channel') {
                            return <span key={index} className={styles[mentionType]} onClick={() => handleChannelClick(mentionId)}>{mentionName}</span>;
                        }
                        return <span key={index} className={styles[mentionType]}>{mentionName}</span>;
                    }
                    return null;
                })}
                {lineIndex < message.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <div className={styles.popupOverlay} onClick={saveAndClose}>
            <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.messageEditor}>
                    <div className={styles.editorSection}>
                        <div>
                            <div className={styles.row} >
                                <label htmlFor="message">Nachricht</label>
                                <span> • {charCount}/2000</span>
                                <div className={styles.buttonGroup}>
                                    <AtButton textBoxId="message" />
                                    <EmojiButton textBoxId="message" />
                                    <FormattingButton textBoxId="message" />
                                </div>
                            </div>
                            <textarea
                                id="message"
                                className={styles.textArea}
                                value={message}
                                onChange={handleMessageChange}
                                maxLength="2000"
                            />
                        </div>
                        <div className={styles.embedEditor}>
                            {embeds.map((embed, index) => (
                                <div key={index} className={styles.embedItem}>
                                    <div className={styles.embedItemHeader} onClick={() => toggleEmbedContent(index)}>
                                        <span>Embed {index + 1}</span>
                                        <span>▼</span>
                                        <button className={styles.trashButton} onClick={() => deleteEmbed(index)}><FaTrash /></button>
                                    </div>
                                    <div id={`embedContent-${index}`} className={styles.embedItemContent}>
                                        <div className={styles.embedField}>
                                            <label htmlFor={`embedTitle-${index}`}>Titel</label>
                                            <input
                                                id={`embedTitle-${index}`}
                                                type="text"
                                                value={embed.title}
                                                onChange={(e) => handleEmbedChange(index, 'title', e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.embedField}>
                                            <label htmlFor={`embedDescription-${index}`}>Beschreibung</label>
                                            <div className={styles.descriptionContainer}>
                                                <div className={styles.buttonGroup}>
                                                    <AtButton textBoxId={`embedDescription-${index}`} />
                                                    <EmojiButton textBoxId={`embedDescription-${index}`} />
                                                    <FormattingButton textBoxId={`embedDescription-${index}`} />
                                                </div>
                                                <textarea
                                                    id={`embedDescription-${index}`}
                                                    value={embed.description}
                                                    onChange={(e) => handleEmbedChange(index, 'description', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.embedField}>
                                            <label htmlFor={`embedColor-${index}`}>Farbe</label>
                                            <input
                                                id={`embedColor-${index}`}
                                                type="color"
                                                value={embed.color}
                                                onChange={(e) => handleEmbedChange(index, 'color', e.target.value)}
                                                className={styles.colorPicker}
                                            />
                                        </div>
                                        <div className={styles.dropdownItem}>
                                            <div className={styles.dropdownItemHeader} onClick={() => toggleDropdownContent(index, 'img')}>
                                                <span>Embed Bild</span>
                                                <span>▼</span>
                                            </div>
                                            <div id={`imgContent-${index}`} className={styles.dropdownItemContent}>
                                                <div className={styles.embedField}>
                                                    <label htmlFor={`embedImage-${index}`}>Embed Bild URL</label>
                                                    <input
                                                        id={`embedImage-${index}`}
                                                        type="text"
                                                        value={embed.image}
                                                        onChange={(e) => handleEmbedChange(index, 'image', e.target.value)}
                                                    />
                                                </div>
                                                <div className={styles.embedField}>
                                                    <label htmlFor={`embedThumbnail-${index}`}>Thumbnail URL</label>
                                                    <input
                                                        id={`embedThumbnail-${index}`}
                                                        type="text"
                                                        value={embed.thumbnail}
                                                        onChange={(e) => handleEmbedChange(index, 'thumbnail', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.dropdownItem}>
                                            <div className={styles.dropdownItemHeader} onClick={() => toggleDropdownContent(index, 'author')}>
                                                <span>Autor</span>
                                                <span>▼</span>
                                            </div>
                                            <div id={`authorContent-${index}`} className={styles.dropdownItemContent}>
                                                <div className={styles.embedField}>
                                                    <label htmlFor={`embedAuthorName-${index}`}>Autor Name</label>
                                                    <input
                                                        id={`embedAuthorName-${index}`}
                                                        type="text"
                                                        value={embed.author.name}
                                                        onChange={(e) => handleEmbedChange(index, 'author.name', e.target.value)}
                                                    />
                                                </div>
                                                <div className={styles.embedField}>
                                                    <label htmlFor={`embedAuthorUrl-${index}`}>Autor URL</label>
                                                    <input
                                                        id={`embedAuthorUrl-${index}`}
                                                        type="text"
                                                        value={embed.author.url}
                                                        onChange={(e) => handleEmbedChange(index, 'author.url', e.target.value)}
                                                    />
                                                </div>
                                                <div className={styles.embedField}>
                                                    <label htmlFor={`embedAuthorIconUrl-${index}`}>Autor Icon URL</label>
                                                    <input
                                                        id={`embedAuthorIconUrl-${index}`}
                                                        type="text"
                                                        value={embed.author.iconUrl}
                                                        onChange={(e) => handleEmbedChange(index, 'author.iconUrl', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.dropdownItem}>
                                            <div className={styles.dropdownItemHeader} onClick={() => toggleDropdownContent(index, 'footer')}>
                                                <span>Fußzeile</span>
                                                <span>▼</span>
                                            </div>
                                            <div id={`footerContent-${index}`} className={styles.dropdownItemContent}>
                                                <div className={styles.embedField}>
                                                    <label htmlFor={`embedFooterText-${index}`}>Fußzeile Text</label>
                                                    <input
                                                        id={`embedFooterText-${index}`}
                                                        type="text"
                                                        value={embed.footer.text}
                                                        onChange={(e) => handleEmbedChange(index, 'footer.text', e.target.value)}
                                                    />
                                                </div>
                                                <div className={styles.embedField}>
                                                    <label htmlFor={`embedFooterIconUrl-${index}`}>Fußzeile Icon URL</label>
                                                    <input
                                                        id={`embedFooterIconUrl-${index}`}
                                                        type="text"
                                                        value={embed.footer.iconUrl}
                                                        onChange={(e) => handleEmbedChange(index, 'footer.iconUrl', e.target.value)}
                                                    />
                                                </div>
                                                <div className={styles.embedField}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={embed.footer.timestamp}
                                                            onChange={(e) => handleEmbedChange(index, 'footer.timestamp', e.target.checked)}
                                                        />
                                                        Timestamp
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.dropdownItem}>
                                            <div className={styles.dropdownItemHeader} onClick={() => toggleDropdownContent(index, 'fields')}>
                                                <span>Felder</span>
                                                <span>▼</span>
                                            </div>
                                            <div id={`fieldsContent-${index}`} className={styles.dropdownItemContent}>
                                                <div className={styles.buttonGroup}>
                                                    <button className={styles.button} onClick={() => addInlineField(index)}>Inline Feld hinzufügen</button>
                                                </div>
                                                {embed.fields.map((field, fieldIndex) => (
                                                    <div key={fieldIndex} className={styles.fieldItem}>
                                                        <div className={styles.fieldItemHeader} onClick={() => toggleFieldContent(index, fieldIndex)}>
                                                            <span>Feld {fieldIndex + 1}</span>
                                                            <span>▼</span>
                                                        </div>
                                                        <div id={`fieldContent-${index}-${fieldIndex}`} className={styles.fieldItemContent}>
                                                            <div className={styles.embedField}>
                                                                <label htmlFor={`fieldName-${index}-${fieldIndex}`}>Feldname</label>
                                                                <input
                                                                    id={`fieldName-${index}-${fieldIndex}`}
                                                                    type="text"
                                                                    value={field.name}
                                                                    onChange={(e) => handleFieldChange(index, fieldIndex, 'name', e.target.value)}
                                                                />
                                                            </div>
                                                            <div className={styles.embedField}>
                                                                <label htmlFor={`fieldValue-${index}-${fieldIndex}`}>Feldwert</label>
                                                                <div className={styles.descriptionContainer}>
                                                                    <div className={styles.buttonGroup}>
                                                                        <AtButton textBoxId={`fieldValue-${index}-${fieldIndex}`} />
                                                                        <EmojiButton textBoxId={`fieldValue-${index}-${fieldIndex}`} />
                                                                        <FormattingButton textBoxId={`fieldValue-${index}-${fieldIndex}`} />
                                                                    </div>
                                                                    <textarea
                                                                        id={`fieldValue-${index}-${fieldIndex}`}
                                                                        value={field.value}
                                                                        onChange={(e) => handleFieldChange(index, fieldIndex, 'value', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={styles.inlineField}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={field.inline}
                                                                        onChange={(e) => handleFieldChange(index, fieldIndex, 'inline', e.target.checked)}
                                                                    />
                                                                    Inline
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className={styles.imageEditor}>
                                {images.map((image, index) => (
                                    <div key={index} className={styles.imageItem}>
                                        <div className={styles.imageItemHeader} onClick={() => toggleImageContent(index)}>
                                            <span>Bild {index + 1}</span>
                                            <span>▼</span>
                                            <button className={styles.trashButton} onClick={() => deleteImage(index)}><FaTrash /></button>
                                        </div>
                                        <div id={`imageContent-${index}`} className={styles.imageItemContent}>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`imageUrl-${index}`}>Bild URL</label>
                                                <input
                                                    id={`imageUrl-${index}`}
                                                    type="text"
                                                    value={image}
                                                    onChange={(e) => handleImageChange(index, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.selfRoleEditor}>
                                {selfRoles.map((selfRole, index) => (
                                    <div key={index} className={styles.selfRoleItem}>
                                        <div className={styles.selfRoleItemHeader} onClick={() => toggleSelfRoleContent(index)}>
                                            <span>Selbst-Rollen-Knopf {index + 1}</span>
                                            <span>▼</span>
                                            <button className={styles.trashButton} onClick={() => deleteSelfRole(index)}><FaTrash /></button>
                                        </div>
                                        <div id={`selfRoleContent-${index}`} className={styles.selfRoleItemContent}>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`selfRoleType-${index}`}>Typ</label>
                                                <input
                                                    id={`selfRoleType-${index}`}
                                                    type="text"
                                                    value={selfRole.type}
                                                    onChange={(e) => handleSelfRoleChange(index, 'type', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`selfRoleStyle-${index}`}>Stil</label>
                                                <input
                                                    id={`selfRoleStyle-${index}`}
                                                    type="text"
                                                    value={selfRole.style}
                                                    onChange={(e) => handleSelfRoleChange(index, 'style', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`selfRoleLabel-${index}`}>Label</label>
                                                <input
                                                    id={`selfRoleLabel-${index}`}
                                                    type="text"
                                                    value={selfRole.label}
                                                    onChange={(e) => handleSelfRoleChange(index, 'label', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`selfRoleEmoji-${index}`}>Emoji</label>
                                                <input
                                                    id={`selfRoleEmoji-${index}`}
                                                    type="text"
                                                    value={selfRole.emoji}
                                                    onChange={(e) => handleSelfRoleChange(index, 'emoji', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`selfRoleRoleName-${index}`}>Rolle Name</label>
                                                <input
                                                    id={`selfRoleRoleName-${index}`}
                                                    type="text"
                                                    value={selfRole.role.name}
                                                    onChange={(e) => handleSelfRoleChange(index, 'role.name', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`selfRoleRoleId-${index}`}>Rolle ID</label>
                                                <input
                                                    id={`selfRoleRoleId-${index}`}
                                                    type="text"
                                                    value={selfRole.role.id}
                                                    onChange={(e) => handleSelfRoleChange(index, 'role.id', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.linkButtonEditor}>
                                {linkButtons.map((linkButton, index) => (
                                    <div key={index} className={styles.linkButtonItem}>
                                        <div className={styles.linkButtonItemHeader} onClick={() => toggleLinkButtonContent(index)}>
                                            <span>Link-Knopf {index + 1}</span>
                                            <span>▼</span>
                                            <button className={styles.trashButton} onClick={() => deleteLinkButton(index)}><FaTrash /></button>
                                        </div>
                                        <div id={`linkButtonContent-${index}`} className={styles.linkButtonItemContent}>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`linkButtonLabel-${index}`}>Label</label>
                                                <input
                                                    id={`linkButtonLabel-${index}`}
                                                    type="text"
                                                    value={linkButton.label}
                                                    onChange={(e) => handleLinkButtonChange(index, 'label', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`linkButtonUrl-${index}`}>URL</label>
                                                <input
                                                    id={`linkButtonUrl-${index}`}
                                                    type="text"
                                                    value={linkButton.url}
                                                    onChange={(e) => handleLinkButtonChange(index, 'url', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.embedField}>
                                                <label htmlFor={`linkButtonEmoji-${index}`}>Emoji</label>
                                                <input
                                                    id={`linkButtonEmoji-${index}`}
                                                    type="text"
                                                    value={linkButton.emoji}
                                                    onChange={(e) => handleLinkButtonChange(index, 'emoji', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.functionBoxContainer}>
                            <div className={styles.functionBox} onClick={addEmbed}>
                                <h2>Nachricht Embed hinzufügen</h2>
                                <p>Fügt eine neue Embed-Nachricht hinzu. Limit: 10 Embeds.</p>
                            </div>
                            <div className={styles.functionBox} onClick={addImage}>
                                <h2>Bilder anhängen</h2>
                                <p>Ermöglicht das Anhängen von Bildern an die Nachricht. Limit: 5 Bilder.</p>
                            </div>
                            <div className={styles.functionBox} onClick={addSelfRole}>
                                <h2>Selbst-Rollen-Knopf hinzufügen</h2>
                                <p>Fügt einen Knopf hinzu, mit dem Benutzer sich selbst Rollen zuweisen können.</p>
                            </div>
                            <div className={styles.functionBox} onClick={addLinkButton}>
                                <h2>Link-Knopf hinzufügen</h2>
                                <p>Fügt einen Knopf hinzu, der auf einen externen Link verweist.</p>
                            </div>
                        </div>
                        <button className={styles.closeButton} onClick={saveAndClose}>Schließen</button>
                    </div>
                    <div className={styles.previewSection}>
                        <h3>Preview</h3>
                        <div className={styles.discordMessage}>
                            <img src={botAvatar} alt="Bot Avatar" className={styles.botAvatar} />
                            <div className={styles.messageContent}>
                                <div className={styles.messageHeader}>
                                    <span className={styles.botName}>Bot</span>
                                    <span className={styles.timestamp}>{new Date().toLocaleString()}</span>
                                </div>
                                <div className={styles.messageText}>{renderMessageWithEmojisAndMentions(message)}</div>
                                {images.length > 0 && (
                                    <div className={styles.imagePreview}>
                                        {images.map((image, index) => (
                                            <img key={index} src={image} alt={`Image ${index + 1}`} className={styles.previewImage} />
                                        ))}
                                    </div>
                                )}
                                {embeds.map((embed, index) => (
                                    <div key={index} className={styles.discordEmbed} style={{ borderColor: embed.color }}>
                                        {embed.title && <h4 className={styles.embedTitle}>{embed.title}</h4>}
                                        {embed.description && <p className={styles.embedDescription}>{renderMessageWithEmojisAndMentions(embed.description)}</p>}
                                        {embed.image && <img src={embed.image} alt="Embed Image" className={styles.embedImage} />}
                                        {embed.thumbnail && <img src={embed.thumbnail} alt="Thumbnail" className={styles.embedThumbnail} />}
                                        {embed.fields.length > 0 && (
                                            <div className={styles.embedFields}>
                                                {embed.fields.map((field, fieldIndex) => (
                                                    <div key={fieldIndex} className={field.inline ? styles.embedFieldInline : styles.embedField}>
                                                        <strong>{field.name}</strong>: {renderMessageWithEmojisAndMentions(field.value)}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {embed.author.name && (
                                            <div className={styles.embedAuthor}>
                                                {embed.author.iconUrl && <img src={embed.author.iconUrl} alt="Author Icon" className={styles.authorIcon} />}
                                                <strong>{embed.author.name}</strong> {embed.author.url && <a href={embed.author.url}>{embed.author.url}</a>}
                                            </div>
                                        )}
                                        {embed.footer.text && (
                                            <div className={styles.embedFooter}>
                                                {embed.footer.iconUrl && <img src={embed.footer.iconUrl} alt="Footer Icon" className={styles.footerIcon} />}
                                                {embed.footer.text}
                                                {embed.footer.timestamp && <span className={styles.footerTimestamp}>{new Date().toLocaleString()}</span>}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {selfRoles.map((selfRole, index) => (
                                    <div key={index} className={styles.selfRolePreview}>
                                        <strong>{selfRole.label}</strong>
                                    </div>
                                ))}
                                {linkButtons.map((linkButton, index) => (
                                    <div key={index} className={styles.linkButtonPreview}>
                                        <strong>{linkButton.label}</strong>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {errors.length > 0 && (
                            <div className={styles.error}>
                                {errors.map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageEditor;