import React, { useEffect, useState } from 'react';
import styles from './LoginPage.module.css';
import logo from '../assets/images/logo.gif';

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const checkToken = async () => {
            setLoading(true);

            let userToken = null;
    
            if (localStorage.getItem('userToken')) {
                userToken = JSON.parse(localStorage.getItem('userToken')).token;
            };
    
            console.log(userToken);
    
            const response = await fetch('https://ayodev.de:8443/api/validity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            }); 
    
            if (response.ok) {
                window.location.href = '/dashboard';
            } else { 
                localStorage.clear();
                setLoading(false);
            }
        }

        const checkForNewBot = async () => {
            console.log('Checking for new bot');
            const urlParams = new URLSearchParams(window.location.search);
            const botCode = urlParams.get('code');  
            console.log(botCode);


            if (botCode) {
                localStorage.setItem('botCode', botCode);
            } else {
                checkToken();
            }
        }

        checkForNewBot();
    }, []);

    const returnToMainPage = () => {
        window.location.href = "https://ayodev.de";
    }

    const handleLogin = () => {
        window.location.href = "https://discord.com/oauth2/authorize?client_id=1188249005252165725&response_type=code&redirect_uri=https%3A%2F%2Fayodev.de%2Fdashboard&scope=identify+guilds+email";
    };

    if (loading) {
        return (
            <div className={styles['spinner-container']}>
                <div className={styles.spinner}></div>  {/* Spinner-Element */}
            </div>
        );
    }

    return (
        <div>
            <div className={styles['login-container']}>
                <div className={styles['login-close-button']} onClick={returnToMainPage}>✕</div>
                <div className={styles['login-top']}>
                    <img className={styles['login-logo']} src={logo} alt="Logo" />
                    <h1 className={styles['login-headline']}><span className={styles['login-span-headline']}>DISCORD</span> Login</h1>
                </div>
                <input type="button" value="Mit Discord anmelden" className={styles['login-button']} onClick={handleLogin} />
                <p className={styles['login-disclaimer']}>
                    Wir verwenden Cookies und speichern einige Nutzerinformationen, um die Funktionalität der Webseite zu gewährleisten. 
                    Es werden keine Daten zum Tracking verwendet und keine Daten verkauft. 
                    <span className={styles['login-disclaimer-bottom']}>
                        Mit dem Klicken des Einloggen-Buttons erklären Sie sich mit unserer 
                        <a href="/terms-of-service" target="_blank" className={styles['login-link']}> Datenschutzerklärung</a> und 
                        <a href="/impressum" target="_blank" className={styles['login-link']}> Impressum</a> einverstanden.
                    </span>
                </p>
            </div>
        </div>
    );
};

export default LoginPage;