import React, { useState } from 'react';
import styles from './AdminSidebar.module.css';
import { FaHome, FaChartBar, FaRobot, FaEnvelope, FaAngleDoubleLeft, FaAngleDoubleRight, FaUsers, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const AdminSidebar = ({ isOpen, toggleSidebar, setActivePage }) => {
    const [isBotControlOpen, setIsBotControlOpen] = useState(false);

    return (
        <div className={`${styles.adminSidebar} ${isOpen ? styles.open : styles.closed}`}>
            <div className={styles.sidebarHeader}>
                {isOpen && <img src="https://i.imgur.com/C40PDBj.gif" alt="Logo" className={styles.logo} />}
                {isOpen && <span className={styles.sidebarTitle}>AyoBots</span>}
                <button onClick={toggleSidebar} className={styles.toggleButton}>
                    {isOpen ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
                </button>
            </div>
            <ul>
                <li onClick={() => setActivePage('dashboard')}>
                    <FaHome className={styles.icon} /> {isOpen && 'Dashboard'}
                </li>
                <li onClick={() => setActivePage('statistics')}>
                    <FaChartBar className={styles.icon} /> {isOpen && 'Statistics'}
                </li>
                <li onClick={() => setActivePage('messages')}>
                    <FaEnvelope className={styles.icon} /> {isOpen && 'Messages'}
                </li>
                <li onClick={() => setActivePage('users')}>
                    <FaUsers className={styles.icon} /> {isOpen && 'Users'}
                </li>
                <li onClick={() => setActivePage('bots')}>
                    <FaRobot className={styles.icon} /> {isOpen && 'Bots'}
                </li>
                <li onClick={() => setIsBotControlOpen(!isBotControlOpen)}>
                    <FaRobot className={styles.icon} /> {isOpen && 'Bot Control Point'}
                    {isOpen && (isBotControlOpen ? <FaChevronUp className={styles.arrowIcon} /> : <FaChevronDown className={styles.arrowIcon} />)}
                </li>
                {isBotControlOpen && (
                    <ul className={styles.subMenu}>
                        <li onClick={() => setActivePage('power')}>
                            {isOpen && 'Power'}
                        </li>
                        <li onClick={() => setActivePage('command')}>
                            {isOpen && 'Command'}
                        </li>
                        <li onClick={() => setActivePage('updates')}>
                            {isOpen && 'Updates'}
                        </li>
                        <li onClick={() => setActivePage('tokens')}>
                            {isOpen && 'Tokens'}
                        </li>
                    </ul>
                )}
            </ul>
        </div>
    );
};

export default AdminSidebar;
