import React, { useState, useEffect } from 'react';
import styles from './TokensContent.module.css';

const TokensContent = () => {
    const [token, setToken] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [discordId, setDiscordId] = useState('');
    const [message, setMessage] = useState('');
    const [tokenStats, setTokenStats] = useState({ totalTokens: 0, usedTokens: 0, unusedTokens: 0 });

    const fetchTokenStats = async () => {
        try {
            const response = await fetch('https://ayodev.de:8443/admin/getCurrentTokenEntrys', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setTokenStats(data);
            } else {
                setMessage(`Error: ${data.message}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    useEffect(() => {
        fetchTokenStats();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://ayodev.de:8443/admin/postNewTokenEntry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`,
                },
                body: JSON.stringify({ token, clientSecret, discordId }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage('Token entry created successfully');
                fetchTokenStats();
                setToken(''); 
                setClientSecret('');
                setDiscordId('');
            } else {
                setMessage(`Error: ${data.message}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className={styles.container}>
            <h1>Create New Token</h1>
            <div className={styles.section}>
                <p>Total Tokens: {tokenStats.totalTokens}</p>
                <p>Used Tokens: {tokenStats.usedTokens}</p>
                <p>Unused Tokens: {tokenStats.unusedTokens}</p>
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.section}>
                    <label>Token:</label>
                    <input
                        type="text"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        required
                        className={styles.textBox}
                    />
                </div>
                <div className={styles.section}>
                    <label>Client Secret:</label>
                    <input
                        type="text"
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                        required
                        className={styles.textBox}
                    />
                </div>
                <div className={styles.section}>
                    <label>Discord ID:</label>
                    <input
                        type="text"
                        value={discordId}
                        onChange={(e) => setDiscordId(e.target.value)}
                        required
                        className={styles.textBox}
                    />
                </div>
                <button type="submit" className={styles.button}>Create Token</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default TokensContent;
