async function saveConfig(configData) {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const configName = pathSegments[pathSegments.length - 1];

    const formattedData = JSON.stringify(configData, null, 2);

    const response = await fetch('https://ayodev.de:8443/api/saveConfig', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
        },
        body: JSON.stringify({ botToken: JSON.parse(localStorage.getItem('botToken')).botToken, configName: configName, fileContent: formattedData })
    });
    if (!response.ok) {
        throw new Error('Failed to save configuration');
    }
}

export { saveConfig };
