import React from 'react';
import Join_Leave_Module from './ConfigPages/Join_Leave_Module';
import Verify_Module from './ConfigPages/Verify_Module';

const ConfigPage = () => {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const configName = pathSegments[pathSegments.length - 1];

    const renderConfigPage = () => {

        switch (configName) {
            case 'Verify_Module':
                return <Verify_Module />;
            case 'Join_Leave_Module':
                return <Join_Leave_Module />;
            case 'EmbedCreator_Module':
            return <div>EmbedCreator Module Placeholder</div>;
            case 'Vorschlag_Module':
            return <div>Vorschlag Module Placeholder</div>;
            case 'Ban_Module':
            return <div>Ban Module Placeholder</div>;
            case 'Kick_Module':
            return <div>Kick Module Placeholder</div>;
            case 'Boost_Module':
            return <div>Boost Module Placeholder</div>;
            case 'SlowMode_Module':
            return <div>SlowMode Module Placeholder</div>;
            case 'DMall_Module':
            return <div>DMall Module Placeholder</div>;
            case 'Roleall_Module':
            return <div>Roleall Module Placeholder</div>;
            case 'Status_Module':
            return <div>Status Module Placeholder</div>;
            case 'RoleBackup_Module':
            return <div>RoleBackup Module Placeholder</div>;
            case 'WarteSchlangen_Module':
            return <div>WarteSchlangen Module Placeholder</div>;
            case 'ChannelLock_Module':
            return <div>ChannelLock Module Placeholder</div>;
            case 'ImageOnly_Module':
            return <div>ImageOnly Module Placeholder</div>;
            case 'PurgeMessage_Module':
            return <div>PurgeMessage Module Placeholder</div>;
            case 'AntiSpam_Module':
            return <div>AntiSpam Module Placeholder</div>;
            case 'Whitelist_Module':
            return <div>Whitelist Module Placeholder</div>;
            case 'AntiAD_Swear_Module':
            return <div>AntiAD_Swear Module Placeholder</div>;
            case 'AFK_Module':
            return <div>AFK Module Placeholder</div>;
            case 'TicketSystem_Module':
            return <div>TicketSystem Module Placeholder</div>;
            case 'Eintrag_Module':
            return <div>Eintrag Module Placeholder</div>;
            case 'Teamliste_Module':
            return <div>Teamliste Module Placeholder</div>;
            case 'Teamupdate_Module':
            return <div>Teamupdate Module Placeholder</div>;
            case 'TwitchNotify_Module':
            return <div>TwitchNotify Module Placeholder</div>;
            case 'GiveAway_Module':
            return <div>GiveAway Module Placeholder</div>;
            case 'Pingroles_Module':
            return <div>Pingroles Module Placeholder</div>;
            case 'Abmelden_Module':
            return <div>Abmelden Module Placeholder</div>;
            case 'ServerLogs_Module':
            return <div>ServerLogs Module Placeholder</div>;
            case 'Umfrage_Module':
            return <div>Umfrage Module Placeholder</div>;
            case 'Warn_Module':
            return <div>Warn Module Placeholder</div>;
            case 'SupportNotify_Module':
            return <div>SupportNotify Module Placeholder</div>;
            case 'TempBanMute_Module':
            return <div>TempBanMute Module Placeholder</div>;
            case 'StickyMessage_Module':
            return <div>StickyMessage Module Placeholder</div>;
            case 'Adventskalender_Module':
            return <div>Adventskalender Module Placeholder</div>;
            case 'Feedback_Module':
            return <div>Feedback Module Placeholder</div>;
            case 'OrderSystem_Module':
            return <div>OrderSystem Module Placeholder</div>;
            case 'PreviewSystem_Module':
            return <div>PreviewSystem Module Placeholder</div>;
            default:
            return <div>No Module Placeholder</div>;
        }
    };

    return (
        <div>
            {renderConfigPage()}
        </div>
    );
};

export default ConfigPage;