import React, { useState, useEffect, useRef } from 'react';
import styles from './EmojiButton.module.css';
import emojiData from '../../../../assets/emojis.json';

const EmojiButton = ({ textBoxId }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [customEmojis, setCustomEmojis] = useState([]);
    const [standardEmojis, setStandardEmojis] = useState([]);
    const [displayedEmojis, setDisplayedEmojis] = useState([]);
    const [chunkIndex, setChunkIndex] = useState(0);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const parseCustomEmojis = () => {
            if (!localStorage.getItem('botDetails')) return [];
            const emoteString = JSON.parse(localStorage.getItem('botDetails'))[0].emotes;
            return emoteString.split('|bigcut§!').map(emote => {
                const [label, id] = emote.split('|smallcut§!');
                return {
                    id: id.trim(),
                    label: label.trim(),
                    url: `https://cdn.discordapp.com/emojis/${id.trim()}.png`
                };
            });
        };

        const parseStandardEmojis = (emojis) => {
            return emojis.map(emoji => {
            return {
                name: emoji.shortcodes[0],
                unicode: emoji.unicode
            };
            });
        };

        setCustomEmojis(parseCustomEmojis());
        setStandardEmojis(parseStandardEmojis(emojiData.emojis));
    }, []);

    useEffect(() => {
        loadMoreEmojis();
    }, [chunkIndex, searchTerm, customEmojis, standardEmojis]);

    const loadMoreEmojis = () => {
        const chunkSize = 50;
        const filteredCustomEmojis = Array.isArray(customEmojis) ? customEmojis.filter(emoji => 
            emoji.label.includes(searchTerm) || emoji.id.includes(searchTerm)
        ) : [];
        const filteredStandardEmojis = Array.isArray(standardEmojis) ? standardEmojis.filter(emoji => 
            emoji.name.includes(searchTerm) || emoji.unicode.includes(searchTerm)
        ) : [];
        const combinedEmojis = [...filteredCustomEmojis, ...filteredStandardEmojis];
        const newEmojis = combinedEmojis.slice(0, (chunkIndex + 1) * chunkSize);
        setDisplayedEmojis(newEmojis);
    };

    const handleClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleOptionClick = (value, isCustomEmoji) => {
        const textBox = document.getElementById(textBoxId);
        if (textBox) {
            if (isCustomEmoji) {
                textBox.value += `${value.label}`;
            } else {
                textBox.value += value.unicode;
            }
            textBox.dispatchEvent(new Event('input', { bubbles: true }));
        }
        setShowDropdown(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            setChunkIndex(chunkIndex + 1);
        }
    };

    return (
        <div className={styles.container}>
            <button className={styles.button} onClick={handleClick}>😊</button>
            {showDropdown && (
                <div className={styles.dropdown} ref={dropdownRef} onScroll={handleScroll}>
                    <input
                        type="text"
                        className={styles.searchBar}
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className={styles.options}>
                        {displayedEmojis.map((emoji, index) => (
                            <div
                                key={index}
                                className={styles.option}
                                onClick={() => handleOptionClick(emoji, !!emoji.url)}
                            >
                                {emoji.url ? (
                                    <img src={emoji.url} alt={emoji.label} className={styles.emojiImage} />
                                ) : (
                                    emoji.unicode
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmojiButton;
