import { func } from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import styles from './BotPage.module.css';
import HeadBar from '../components/HeadBar';
import ServerDisplay from '../components/botPageComponents/ServerDisplay';
import BotInfo from '../components/botPageComponents/BotInfo';
import ConfigList from '../components/botPageComponents/ConfigList';
import Stats from '../components/botPageComponents/Stats';
import ActionLog from '../components/botPageComponents/ActionLog';
import Permissions from '../components/botPageComponents/Permissions';
import Footer from '../components/Footer';
import UserList from '../components/botPageComponents/AyoCore';
import { MessageContext } from '../context/MessageContext';

const BotPage = () => {
    const [loading, setLoading] = useState(true);
    const [botDetails, setBotDetails] = useState(null);
    const { sendMessage } = useContext(MessageContext);

    window.onload = function() {
        setLoading(true);
    };

    useEffect(() => {
        const fetchMessages = async () => {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const location = '/bot';
            const response = await fetch(`https://ayodev.de:8443/api/activeMessages?location=${location}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                if (data) {
                    const messages = data.messages;
                    messages.forEach(message => {
                        sendMessage(message.message, message.type);
                    });
                }
            } else {
                console.error('Failed to fetch messages from API');
            }
        };

        fetchMessages();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            var uuid = urlParams.get('uuid');
            if (!uuid) {
                uuid = JSON.parse(localStorage.getItem('currentBot')).UUID;
            }
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;
            console.log(uuid, userToken);

            if (uuid) {
                try {
                    const response = await fetch('https://ayodev.de:8443/api/botToken', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${userToken}`
                        },
                        body: JSON.stringify({ uuid: uuid })
                    });

                    if (response.ok) {
                        const data = await response.json();
                        localStorage.setItem('botToken', JSON.stringify(data));

                        if (urlParams) {
                            urlParams.delete('code');
                            window.history.replaceState(null, null, window.location.pathname);
                        }

                        const token = data.botToken;
                        console.log(token, userToken);
                        const botsResponse = await fetch('https://ayodev.de:8443/api/botInfo', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${userToken}`
                            },
                            body: JSON.stringify({ botToken: token })
                        });

                        if (botsResponse.ok) {
                            const botsData = await botsResponse.json();
                            console.log(botsData);
                            setBotDetails(botsData.botDetails);
                            localStorage.removeItem('botDetails');
                            localStorage.setItem('botDetails', JSON.stringify(botsData.botDetails));
                        } else {
                            if (response.status === 403) {
                                localStorage.clear();
                                window.location.href = '/login';
                            }
                            console.error('Failed to fetch bots data from API');
                        }
                    } else {
                        if (response.status === 403) {
                            localStorage.clear();
                            window.location.href = '/login';
                        }
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
                getBotData();
            }
        };


        async function getBotData() {
            const botsResponse = await fetch('https://ayodev.de:8443/api/bots', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
                }
            });

            if (botsResponse.ok) {
                const botsData = await botsResponse.json();
                localStorage.setItem('botsData', JSON.stringify(botsData));
            }

            const botsData = JSON.parse(localStorage.getItem('botsData'));
            const storedBotDetails = JSON.parse(localStorage.getItem('botDetails'));
            const id = storedBotDetails[0].botID;

            botsData.botInfo.forEach(bot => {
                if (bot.UUID === id) {
                    localStorage.setItem('currentBot', JSON.stringify(bot));
                    
                    makeConfigList();
                    setLoading(false);
                }
            });
        }

        function makeConfigList() {
            const botDetails = JSON.parse(localStorage.getItem('botDetails'));
            const botConfigs = botDetails[0].configs;

            const configs = botConfigs.split('|bigcut§!').map(config => {
            const [name, status] = config.split('|smallcut§!');
            return { name: name.replace(/_/g, ' '), enabled: status === 'aktiviert' };
            });

            localStorage.setItem('configs', JSON.stringify(configs));
        }

        fetchData();
    }, []);

    useEffect(() => {
        try {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;

            const fetchData = async () => {
            const response = await fetch('https://ayodev.de:8443/api/validity', {
                method: 'POST',
                headers: {
                'Authorization': `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                localStorage.clear();
                window.location.href = '/';
            }
            };

            fetchData();
        } catch (error) {
            console.error('Failed to fetch data from API', error);
            localStorage.clear();
            window.location.href = '/';
        }
    }, []);

    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        );
    }

    const convertUnixTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    };

    return (
        <div className="botPageContainer">
            <HeadBar />
            <BotInfo />
            <div className={styles.botPageRow}>
                <ServerDisplay />
                <ActionLog />
            </div>
            <ConfigList />
            <UserList />
            <Footer />
        </div>
    );
};

export default BotPage;