import React from 'react';
import styles from './Header.module.css';
import logo from '../assets/images/logo.gif';

const Header = () => {
    return (
        <header className={styles.header}>
            <div className={styles['header-content']}>
                <img src={logo} alt="Logo" className={styles.logo} />
                <h1 className={styles.name}>AyoBots</h1>
                <nav className={styles.nav}>
                    <a href="/login" className={styles['dashboard-button']}>Dashboard</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;