import React, { useState, useEffect, useContext } from 'react';
import styles from './Verify_Module.module.css';
import MessageEditor from './utils/MessageEditor';
import { fetchConfig } from './utils/fetchConfig';
import { saveConfig } from './utils/saveConfig';
import { resetConfig } from './utils/resetConfig';
import SelectInput from './utils/SelectInput';
import ConfigHeadbar from './utils/ConfigHeadbar';
import Savebar from './utils/Savebar';
import { MessageContext } from '../../context/MessageContext';

const VerifyModule = () => {
    const [configData, setConfigData] = useState(null);
    const [editedData, setEditedData] = useState(null);
    const [showEditor, setShowEditor] = useState(false);
    const [currentMessageType, setCurrentMessageType] = useState('');
    const [showSavebar, setShowSavebar] = useState(false);
    const [loading, setLoading] = useState(true);
    const { sendMessage } = useContext(MessageContext);
    const [verifyMessageChannels, setVerifyMessageChannels] = useState([]);
    const [selectedOption, setSelectedOption] = useState('verifyButton');

    useEffect(() => {
        const getConfigData = async () => {
            const data = await fetchConfig();
            setConfigData(data);
            setEditedData(data);
            setLoading(false);
            setSelectedOption(data.verifyButton.enabled ? 'verifyButton' : 'ayoCore');
        };
        getConfigData();
    }, []);

    const toggleEditor = (messageType) => {
        setCurrentMessageType(messageType);
        setShowEditor(!showEditor);
        console.log(messageType, editedData[messageType]);
    };

    const saveConfigHandler = async () => {
        await saveConfig(editedData);
        setConfigData(editedData);
        setShowSavebar(false);
        sendMessage('Erfolgreich gespeichert!', 'success');
    };

    const resetChangesHandler = async () => {
        if (window.confirm('Are you sure you want to reset the changes?')) {
            const data = await resetConfig();
            setConfigData(data);
            setEditedData(data);
            setShowSavebar(true);
            sendMessage('Erfolgreich zurückgesetzt!', 'warning');
        }
    };

    const softResetChanges = () => {
        setEditedData(configData);
        setShowSavebar(false);
    };

    const handleButtonTypeChange = (selectedOption, type) => {
        setEditedData((prevData) => ({
            ...prevData,
            [type]: {
                ...prevData[type],
                type: selectedOption.value
            }
        }));
        setShowSavebar(true);
    };

    const handleRoleChange = (selectedOptions, type) => {
        const newItems = selectedOptions.map(option => ({
            name: option.label,
            id: option.value,
            color: option.color
        }));
        setEditedData((prevData) => ({
            ...prevData,
            [type]: newItems
        }));
        setShowSavebar(true);
    };

    const handleChannelChange = (selectedOptions, type) => {
        const newChannels = selectedOptions.map(option => ({
            name: option.label,
            id: option.value
        }));
        setEditedData((prevData) => ({
            ...prevData,
            [type]: newChannels
        }));
        setShowSavebar(true);
    };

    const handleVerifyChannelChange = (selectedOptions, type) => {
        console.log(selectedOptions);
        console.log(Array.isArray(selectedOptions));
        if (!Array.isArray(selectedOptions)) {
            selectedOptions = [selectedOptions];
        }
        const newChannels = selectedOptions.map(option => ({
            name: option.label,
            id: option.value
        }));
        setVerifyMessageChannels(newChannels);
        setShowSavebar(true);
    };

    const handleBooleanChange = (event, type) => {
        const [parent, child] = type.split('.');
        if (child) {
            setEditedData((prevData) => ({
                ...prevData,
                [parent]: {
                    ...prevData[parent],
                    [child]: event.target.checked
                }
            }));
        } else {
            setEditedData((prevData) => ({
                ...prevData,
                [type]: event.target.checked
            }));
        }
        setShowSavebar(true);
    };

    const handleInputChange = (event, type) => {
        const { value } = event.target;
        const [parent, child] = type.split('.');
        if (child) {
            setEditedData((prevData) => ({
                ...prevData,
                [parent]: {
                    ...prevData[parent],
                    [child]: value
                }
            }));
        } else {
            setEditedData((prevData) => ({
                ...prevData,
                [type]: value
            }));
        }
        setShowSavebar(true);
    };

    const handleButtonToggle = (type) => {
        if (type === 'verifyButton.enabled') {
            setEditedData((prevData) => ({
                ...prevData,
                verifyButton: {
                    ...prevData.verifyButton,
                    enabled: !prevData.verifyButton.enabled
                },
                ayoCore: {
                    ...prevData.ayoCore,
                    enabled: prevData.verifyButton.enabled
                }
            }));
        } else if (type === 'ayoCore.enabled') {
            setEditedData((prevData) => ({
                ...prevData,
                verifyButton: {
                    ...prevData.verifyButton,
                    enabled: prevData.ayoCore.enabled
                },
                ayoCore: {
                    ...prevData.ayoCore,
                    enabled: !prevData.ayoCore.enabled
                }
            }));
        } else if (type === 'captchaVerify.enabled') {
            setEditedData((prevData) => ({
                ...prevData,
                captchaVerify: {
                    ...prevData.captchaVerify,
                    enabled: !prevData.captchaVerify.enabled
                }
            }));
        } else {
            setEditedData((prevData) => ({
                ...prevData,
                [type]: !prevData[type]
            }));
        }
        setShowSavebar(true);
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setEditedData((prevData) => ({
            ...prevData,
            verifyButton: {
                ...prevData.verifyButton,
                enabled: option === 'verifyButton'
            },
            ayoCore: {
                ...prevData.ayoCore,
                enabled: option === 'ayoCore'
            }
        }));
        setShowSavebar(true);
    };

    const sendVerifyMessage = () => {
        console.log('Sending verify message to channels:', editedData?.verifyMessageChannels);
    };

    return (
        <div>
            <ConfigHeadbar
                title="Verify Module Configuration"
                onReset={resetChangesHandler}
                backUrl="/dashboard/bot"
            />
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className={styles.container}>
                    <div className='row'>
                        <div className={styles.selectContainer}>
                            <label className={styles.selectLabel}>Post Message Channels</label>
                            <SelectInput
                                selectedItems={editedData?.verifyMessageChannels || []}
                                onChange={(selectedOptions) => handleChannelChange(selectedOptions, 'verifyMessageChannels')}
                                type="channels"
                                multi={true}
                            />
                            <div className='row'>
                                <button onClick={sendVerifyMessage}>
                                    Send Verify Message
                                </button>
                                <button onClick={() => toggleEditor('verifyMessage')}>
                                    Edit Verify Message
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sliderContainer} style={{ display: 'flex' }}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={editedData?.rolesOnVerifyOn || false}
                                onChange={(event) => handleBooleanChange(event, 'rolesOnVerifyOn')}
                            />
                            <span className="slider"></span>
                            Roles Add on Verify
                        </label>
                        {editedData?.rolesOnVerifyOn && (
                            <div className={styles.selectContainer}>
                                <label className={styles.selectLabel}>Roles to Add on Verify</label>
                                <SelectInput
                                    selectedItems={editedData?.rolesOnVerify || []}
                                    onChange={(selectedOptions) => handleRoleChange(selectedOptions, 'rolesOnVerify')}
                                    type="roles"
                                    multi={true}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.sliderContainer} style={{ display: 'flex' }}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={editedData?.rolesRemoveOnUnverify || false}
                                onChange={(event) => handleBooleanChange(event, 'rolesRemoveOnUnverify')}
                            />
                            <span className="slider"></span>
                            Roles Remove on Unverify
                        </label>
                        {editedData?.rolesRemoveOnUnverify && (
                            <div className={styles.selectContainer}>
                                <label className={styles.selectLabel}>Roles to Remove on Unverify</label>
                                <SelectInput
                                    selectedItems={editedData?.rolesOnUnVerify || []}
                                    onChange={(selectedOptions) => handleRoleChange(selectedOptions, 'rolesOnUnVerify')}
                                    type="roles"
                                    multi={true}
                                />
                            </div>
                        )}
                    </div>
                    <div className='row' style={{ width: '100%' }}> 
                        <div className={styles.sliderContainer} style={{ display: 'flex', width: '100%', height: '80px' }}>
                            <label className="slider-label">
                                <input
                                    type="checkbox"
                                    className="slider-input"
                                    checked={editedData?.privateMessageOnVerify?.enabled || false}
                                    onChange={(event) => handleBooleanChange(event, 'privateMessageOnVerify.enabled')}
                                />
                                <span className="slider"></span>
                                Private Message on Verify
                            </label>
                            {editedData?.privateMessageOnVerify?.enabled && (
                                <button className={styles.editButton} onClick={() => toggleEditor('privateMessageOnVerify')} style={{ marginLeft: '10px' }}>
                                    Edit Private Message
                                </button>
                            )}
                        </div>
                        <div className={styles.sliderContainer} style={{ display: 'flex', width: '100%', height: '80px' }}>
                            <label className="slider-label">
                                <input
                                    type="checkbox"
                                    className="slider-input"
                                    checked={editedData?.privateMessageOnUnVerify?.enabled || false}
                                    onChange={(event) => handleBooleanChange(event, 'privateMessageOnUnVerify.enabled')}
                                />
                                <span className="slider"></span>
                                Private Message on Unverify
                            </label>
                            {editedData?.privateMessageOnUnVerify?.enabled && (
                                <button className={styles.editButton} onClick={() => toggleEditor('privateMessageOnUnVerify')} style={{ marginLeft: '10px' }}>
                                    Edit Private Message
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={styles.sliderContainer} style={{ display: 'flex' }}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={editedData?.changeNicknameOnVerify?.enabled || false}
                                onChange={(event) => handleBooleanChange(event, 'changeNicknameOnVerify.enabled')}
                            />
                            <span className="slider"></span>
                            Change Nickname on Verify
                        </label>
                        {editedData?.changeNicknameOnVerify?.enabled && (
                            <div className={styles.inputContainer}>
                                <label className={styles.inputLabel}>Change Nickname on Verify</label>
                                <input
                                    type="text"
                                    defaultValue={editedData?.changeNicknameOnVerify?.nickname || ''}
                                    onChange={(event) => {
                                        setEditedData((prevData) => ({
                                            ...prevData,
                                            changeNicknameOnVerify: {
                                                ...prevData.changeNicknameOnVerify,
                                                nickname: event.target.value
                                            }
                                        }));
                                        setShowSavebar(true);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.optionToggle}>
                        <button
                            className={`${styles.optionButton} ${selectedOption === 'verifyButton' ? styles.active : ''}`}
                            onClick={() => handleOptionChange('verifyButton')}
                            style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '0px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
                        >
                            Verify Button
                        </button>
                        <button
                            className={`${styles.optionButton} ${selectedOption === 'ayoCore' ? styles.active : ''}`}
                            onClick={() => handleOptionChange('ayoCore')}
                            style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '5px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
                        >
                            AyoCore
                        </button>
                    </div>
                    {selectedOption === 'verifyButton' && (
                        <div className={styles.buttonContainer} style={{ backgroundColor: '#2a2a3b', padding: '10px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
                            <div className={styles.inputContainer}>
                                <label className={styles.inputLabel}>Verify Button Name</label>
                                <input
                                    type="text"
                                    defaultValue={editedData?.verifyButton?.name || ''}
                                    onChange={(event) => handleInputChange(event, 'verifyButton.name')}
                                />
                            </div>
                            <div className={styles.inputContainer}>
                                <label className={styles.inputLabel}>Verify Button Emoji</label>
                                <input
                                    type="text"
                                    defaultValue={editedData?.verifyButton?.emoji || ''}
                                    onChange={(event) => handleInputChange(event, 'verifyButton.emoji')}
                                />
                            </div>
                            <div className={styles.inputContainer}>
                                <label className={styles.inputLabel}>Verify Button Type</label>
                                <SelectInput
                                    selectedItems={[{ id: editedData?.verifyButton?.type }]}
                                    onChange={(selectedOption) => handleButtonTypeChange(selectedOption, 'verifyButton')}
                                    type="buttonType"
                                    multi={false}
                                />
                            </div>
                            <div className={styles.sliderContainer} style={{ display: 'flex' }}>
                                <label className="slider-label">
                                    <input
                                        type="checkbox"
                                        className="slider-input"
                                        checked={editedData?.captchaVerify?.enabled || false}
                                        onChange={(event) => handleButtonToggle('captchaVerify.enabled')}
                                    />
                                    <span className="slider"></span>
                                    Captcha Verify
                                </label>
                                {editedData?.captchaVerify?.enabled && (
                                    <>
                                        <div className={styles.inputContainer}>
                                            <label className={styles.inputLabel}>Code Type</label>
                                            <SelectInput
                                                selectedItems={[{ id: editedData?.captchaVerify?.type }]}
                                                onChange={(selectedOption) => handleButtonTypeChange(selectedOption, 'captchaVerify')}
                                                type="codeType"
                                                multi={false}
                                            />
                                        </div>
                                        {editedData?.captchaVerify?.type === 'manual' && (
                                            <div className={styles.inputContainer}>
                                                <label className={styles.inputLabel}>Custom Code</label>
                                                <input
                                                    type="text"
                                                    defaultValue={editedData?.captchaVerify?.customCode || ''}
                                                    onChange={(event) => handleInputChange(event, 'captchaVerify.customCode')}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedOption === 'ayoCore' && (
                        <div className={styles.buttonContainer} style={{ backgroundColor: '#2a2a3b', padding: '10px', borderRadius: '5px' }}>
                            <div className={styles.inputContainer}>
                                <label className={styles.inputLabel}>AyoCore Button Name</label>
                                <input
                                    type="text"
                                    defaultValue={editedData?.ayoCore?.name || ''}
                                    onChange={(event) => handleInputChange(event, 'ayoCore.name')}
                                />
                            </div>
                            <div className={styles.inputContainer}>
                                <label className={styles.inputLabel}>AyoCore Button Emoji</label>
                                <input
                                    type="text"
                                    defaultValue={editedData?.ayoCore?.emoji || ''}
                                    onChange={(event) => handleInputChange(event, 'ayoCore.emoji')}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.buttonContainer} style={{ backgroundColor: '#2a2a3b', padding: '10px', borderRadius: '5px' }}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={editedData?.userButton?.enabled || false}
                                onChange={(event) => handleBooleanChange(event, 'userButton.enabled')}
                            />
                            <span className="slider"></span>
                            Enable User Count Button
                        </label>
                        {editedData?.userButton?.enabled && (
                            <>
                                <div className={styles.inputContainer}>
                                    <label className={styles.inputLabel}>User Count Button Name</label>
                                    <input
                                        type="text"
                                        defaultValue={editedData?.userButton?.name || ''}
                                        onChange={(event) => handleInputChange(event, 'userButton.name')}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label className={styles.inputLabel}>User Count Button Emoji</label>
                                    <input
                                        type="text"
                                        defaultValue={editedData?.userButton?.emoji || ''}
                                        onChange={(event) => handleInputChange(event, 'userButton.emoji')}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label className={styles.inputLabel}>User Count Button Type</label>
                                    <SelectInput
                                        selectedItems={[{ id: editedData?.userButton?.type }]}
                                        onChange={(selectedOption) => handleButtonTypeChange(selectedOption, 'userButton')}
                                        type="buttonType"
                                        multi={false}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {showEditor && (
                        <MessageEditor
                            messageData={editedData[currentMessageType]}
                            changeCallback={(newData) => {
                                setEditedData((prevData) => ({
                                    ...prevData,
                                    [currentMessageType]: {
                                        ...prevData[currentMessageType],
                                        message: newData.message,
                                        embeds: newData.embeds,
                                        images: newData.images,
                                        selfRoles: newData.selfRoles,
                                        linkButtons: newData.linkButtons
                                    }
                                }));
                                setShowSavebar(true);
                            }}
                            closeCallback={() => toggleEditor(currentMessageType)}
                        />
                    )}
                </div>
            )}
            <Savebar
                show={showSavebar}
                onSave={saveConfigHandler}
                onSoftReset={softResetChanges}
            />
        </div>
    );
};

export default VerifyModule;
