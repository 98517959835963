import React, { useState, useEffect, useContext } from 'react';
import styles from './Join_Leave_Module.module.css';
import MessageEditor from './utils/MessageEditor';
import { fetchConfig } from './utils/fetchConfig';
import { saveConfig } from './utils/saveConfig';
import { resetConfig } from './utils/resetConfig';
import SelectInput from './utils/SelectInput';
import ConfigHeadbar from './utils/ConfigHeadbar';
import Savebar from './utils/Savebar';
import { MessageContext } from '../../context/MessageContext';

// TODO 

// Add Messages for saving
// Add reset files to server restructure configs 
// Save button auf message box zeigen

const VerifyModule = () => {
    const [configData, setConfigData] = useState(null);
    const [editedData, setEditedData] = useState(null);
    const [showEditor, setShowEditor] = useState(false);
    const [currentMessageType, setCurrentMessageType] = useState('');
    const [showSavebar, setShowSavebar] = useState(false);
    const [loading, setLoading] = useState(true);
    const { sendMessage } = useContext(MessageContext);

    useEffect(() => {
        const getConfigData = async () => {
            const data = await fetchConfig();
            setConfigData(data);
            setEditedData(data);
            setLoading(false);
        };
        getConfigData();
    }, []);

    const toggleEditor = (messageType) => {
        setCurrentMessageType(messageType);
        setShowEditor(!showEditor);
        console.log(messageType, editedData[messageType]);
    };

    const saveConfigHandler = async () => {
        await saveConfig(editedData);
        setConfigData(editedData);
        setShowSavebar(false);
        sendMessage('Erfolgreich gespeichert!', 'success');
    };

    const resetChangesHandler = async () => {
        if (window.confirm('Are you sure you want to reset the changes?')) {
            const data = await resetConfig();
            setConfigData(data);
            setEditedData(data);
            setShowSavebar(true);
            sendMessage('Erfolgreich zurückgesetzt!', 'warning');
        }
    };

    const softResetChanges = () => {
        setEditedData(configData);
        setShowSavebar(false);
    };

    const handleRoleChange = (selectedOptions, type) => {
        const newItems = selectedOptions.map(option => ({
            name: option.label,
            id: option.value,
            color: option.color
        }));
        setEditedData((prevData) => ({
            ...prevData,
            [type]: newItems
        }));
        setShowSavebar(true);
    };

    const handleChannelChange = (selectedOption, type) => {
        const newChannel = {
            name: selectedOption.label,
            id: selectedOption.value
        };
        setEditedData((prevData) => ({
            ...prevData,
            [type]: newChannel
        }));
        setShowSavebar(true);
    };

    const handleBooleanChange = (event, type) => {
        const [parent, child] = type.split('.');
        if (child) {
            setEditedData((prevData) => ({
                ...prevData,
                [parent]: {
                    ...prevData[parent],
                    [child]: event.target.checked
                }
            }));
        } else {
            setEditedData((prevData) => ({
                ...prevData,
                [type]: event.target.checked
            }));
        }
        setShowSavebar(true);
    };

    return (
        <div>
            <ConfigHeadbar
                title="Join/Leave Module Configuration"
                onReset={resetChangesHandler}
                backUrl="/dashboard/bot"
            />
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className={styles.container}>
                    <div className={styles.selectContainer}>
                        <label className={styles.selectLabel}>Join Roles</label>
                        <SelectInput
                            selectedItems={editedData?.joinRoles || []}
                            onChange={(selectedOptions) => handleRoleChange(selectedOptions, 'joinRoles')}
                            type="roles"
                            multi={true}
                        />
                    </div>
                    <div className='row'>
                        <div className={styles.selectContainer}>
                            <label className={styles.selectLabel}>Join Channel</label>
                            <SelectInput
                                selectedItems={[editedData?.joinChannel || {}]}
                                onChange={(selectedOption) => handleChannelChange(selectedOption, 'joinChannel')}
                                type="channels"
                                multi={false}
                            />
                        </div>
                        <button className={styles.editButton} onClick={() => toggleEditor('joinMessage')}>
                            Edit Join Message
                        </button>
                    </div>
                    <div className='row'>
                        <div className={styles.selectContainer}>
                            <label className={styles.selectLabel}>Leave Channel</label>
                            <SelectInput
                                selectedItems={[editedData?.leaveChannel || {}]}
                                onChange={(selectedOption) => handleChannelChange(selectedOption, 'leaveChannel')}
                                type="channels"
                                multi={false}
                            />
                        </div>
                        <button className={styles.editButton} onClick={() => toggleEditor('leaveMessage')}>
                            Edit Leave Message
                        </button>
                    </div>
                    <div className={styles.sliderContainer} style={{ display: 'flex' }}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={editedData?.changeNicknameOnJoin?.enabled || false}
                                onChange={(event) => handleBooleanChange(event, 'changeNicknameOnJoin.enabled')}
                            />
                            <span className="slider"></span>
                            Change Nickname On Join
                        </label>
                        {editedData?.changeNicknameOnJoin?.enabled && (
                            <input
                                type="text"
                                value={editedData?.changeNicknameOnJoin?.nickname || ''}
                                onChange={(event) => {
                                    setEditedData((prevData) => ({
                                        ...prevData,
                                        changeNicknameOnJoin: {
                                            ...prevData.changeNicknameOnJoin,
                                            nickname: event.target.value
                                        }
                                    }));
                                    setShowSavebar(true);
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.sliderContainer}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={editedData?.ignoreBots || false}
                                onChange={(event) => handleBooleanChange(event, 'ignoreBots')}
                            />
                            <span className="slider"></span>
                            Ignore Bots
                        </label>
                    </div>
                    <div className={styles.sliderContainer} style={{ display: 'flex' }}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={editedData?.privateMessage?.enabled || false}
                                onChange={(event) => handleBooleanChange(event, 'privateMessage.enabled')}
                            />
                            <span className="slider"></span>
                            Private Message
                        </label>
                        {editedData?.privateMessage?.enabled && (
                            <button className={styles.editButton} onClick={() => toggleEditor('privateMessage')} style={{ marginLeft: '10px' }}>
                                Edit Private Message
                            </button>
                        )}
                    </div>
                    {showEditor && (
                        <MessageEditor
                            messageData={editedData[currentMessageType]}
                            changeCallback={(newData) => {
                                setEditedData((prevData) => ({
                                    ...prevData,
                                    [currentMessageType]: {
                                        ...prevData[currentMessageType],
                                        message: newData.message,
                                        embeds: newData.embeds,
                                        images: newData.images,
                                        selfRoles: newData.selfRoles,
                                        linkButtons: newData.linkButtons
                                    }
                                }));
                                setShowSavebar(true);
                            }}
                            closeCallback={() => toggleEditor(currentMessageType)}
                        />
                    )}
                </div>
            )}
            <Savebar
                show={showSavebar}
                onSave={saveConfigHandler}
                onSoftReset={softResetChanges}
            />
        </div>
    );
};

export default VerifyModule;