import React from 'react';
import styles from './FormattingButton.module.css';

const FormattingButton = ({ textBoxId }) => {
    const handleClick = () => {
        const textBox = document.getElementById(textBoxId);
        if (textBox) {
            textBox.value += '¶';
            textBox.dispatchEvent(new Event('input', { bubbles: true }));
        }
    };

    return (
        <button className={styles.button} onClick={handleClick}>¶</button>
    );
};

export default FormattingButton;
